import * as types from "./types";

const initState = {
  loading: false,
  data: [],
  success: false,
  workstreams: [],
  teamMembers: [],
  allTeams: [],
  allDepartments: [],
  graphData: [],
  valueGraphData: [],
  valueActivities: [],
  //   activityLogs: [],
  //   row: null,
  //   myActivities: [],
  //   fileMedia: [],
  //   imageMedia: [],
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    //get-activities-by-admin
    case types.ADMIN_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADMIN_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };
    case types.ADMIN_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-workstreams-by-admin
    case types.GET_ALL_WORKSTREAMS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_ALL_WORKSTREAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        workstreams: action.payload,
      };
    case types.GET_ALL_WORKSTREAMS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-teamMembers-reporting
    case types.GET_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: action.payload.data,
        allDepartments: action.payload.associatedDepartments,
        allTeams: action.payload.associatedTeams,
      };
    case types.GET_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-graph-data
    case types.GET_GRAPH_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        graphData: action.payload.data,
        valueGraphData: action.payload.valueData,
        valueActivities: action.payload.activities,
      };
    case types.GET_GRAPH_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-graph-activity
    case types.GET_GRAPH_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_GRAPH_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        valueGraphData: action.payload.valueGraph,
        graphData: action.payload.graph,
      };
    case types.GET_GRAPH_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default Reducer;
