import * as types from "./types";

const initState = {
  loading: false,
  deliverable: [],
  archiveDeliverable: [],
  project: [],
  archiveProject: [],
  program: [],
  archiveProgram: [],
  portfolio: [],
  archivePortfolio: [],
  pendingReview: [],
  logs: [],
  success: false,
};

const teamsReducer = (state = initState, action) => {
  switch (action.type) {
    //get templates
    case types.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        pendingReview: action.payload?.filter(
          (elem) => elem.pendingReview == true
        ),
        deliverable: action.payload?.filter(
          (elem) => elem.type == "deliverable" && elem.status !== "de-activate"
        ),
        project: action.payload?.filter(
          (elem) => elem.type == "project" && elem.status !== "de-activate"
        ),
        program: action.payload?.filter(
          (elem) => elem.type == "program" && elem.status !== "de-activate"
        ),
        portfolio: action.payload?.filter(
          (elem) => elem.type == "portfolio" && elem.status !== "de-activate"
        ),
        archiveDeliverable: action.payload?.filter(
          (elem) => elem.type == "deliverable" && elem.status == "de-activate"
        ),
        archiveProject: action.payload?.filter(
          (elem) => elem.type == "project" && elem.status == "de-activate"
        ),
        archiveProgram: action.payload?.filter(
          (elem) => elem.type == "program" && elem.status == "de-activate"
        ),
        archivePortfolio: action.payload?.filter(
          (elem) => elem.type == "portfolio" && elem.status == "de-activate"
        ),
      };
    case types.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get templates logs
    case types.GET_TEMPLATE_LOG_REQUEST:
      return {
        ...state,
      };
    case types.GET_TEMPLATE_LOG_SUCCESS:
      return {
        ...state,

        logs: action.payload,
      };
    case types.GET_TEMPLATE_LOG_FAILURE:
      return {
        ...state,
      };

    //approve and publish
    case types.APPROVE_PUBLISH_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.APPROVE_PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.APPROVE_PUBLISH_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add templates logs
    case types.ADD_TEMPLATE_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_TEMPLATE_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.ADD_TEMPLATE_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add template
    case types.POST_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.POST_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        pendingReview: [...state.pendingReview, action.payload],
        deliverable:
          action.payload.type == "deliverable"
            ? [...state.deliverable, action.payload]
            : state.deliverable,
        project:
          action.payload.type == "project"
            ? [...state.project, action.payload]
            : state.project,
        program:
          action.payload.type == "program"
            ? [...state.program, action.payload]
            : state.program,
        portfolio:
          action.payload.type == "portfolio"
            ? [...state.portfolio, action.payload]
            : state.portfolio,
      };
    case types.POST_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit template
    case types.PATCH_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.PATCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        pendingReview:
          action.payload.pendingReview == false
            ? state.pendingReview?.filter(
                (elem) => elem._id !== action.payload._id
              )
            : state.pendingReview,
        deliverable:
          action.payload.type == "deliverable"
            ? state.deliverable?.map((elem) =>
                elem?._id == action.payload._id ? action.payload : elem
              )
            : state.deliverable,
        project:
          action.payload.type == "project"
            ? state.project?.map((elem) =>
                elem?._id == action.payload._id ? action.payload : elem
              )
            : state.project,
        program:
          action.payload.type == "program"
            ? state.program?.map((elem) =>
                elem?._id == action.payload._id ? action.payload : elem
              )
            : state.program,
        portfolio:
          action.payload.type == "portfolio"
            ? state.portfolio?.map((elem) =>
                elem?._id == action.payload._id ? action.payload : elem
              )
            : state.portfolio,
      };
    case types.PATCH_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //active template
    case types.ACTIVATE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ACTIVATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        deliverable:
          action.payload.type == "deliverable"
            ? [...state.deliverable, action.payload]
            : state.deliverable,
        project:
          action.payload.type == "project"
            ? [...state.project, action.payload]
            : state.project,
        program:
          action.payload.type == "program"
            ? [...state.program, action.payload]
            : state.program,
        portfolio:
          action.payload.type == "portfolio"
            ? [...state.portfolio, action.payload]
            : state.portfolio,
      };
    case types.ACTIVATE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //de-active template
    case types.DE_ACTIVATE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DE_ACTIVATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        archiveDeliverable:
          action.payload.type == "deliverable"
            ? [...state.archiveDeliverable, action.payload]
            : state.archiveDeliverable,
        archiveProject:
          action.payload.type == "project"
            ? [...state.archiveProject, action.payload]
            : state.archiveProject,
        archiveProgram:
          action.payload.type == "program"
            ? [...state.archiveProgram, action.payload]
            : state.archiveProgram,
        archivePortfolio:
          action.payload.type == "portfolio"
            ? [...state.archivePortfolio, action.payload]
            : state.archivePortfolio,
      };
    case types.DE_ACTIVATE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default teamsReducer;
