/* eslint-disable */
import axios from "../../api/axiosConfig";

//get clients
export const getClients = (data) => {
  return axios.get(`/client/get-clients/${data}`);
};

//create client
export const postClient = (data) => {
  return axios.post(`/client/post-client/${data.id}`, data.data);
};

//edit client
export const editClient = (data) => {
  return axios.patch(`/client/edit-client/${data.id}`, data.data);
};

//enable client
export const enableClient = (data) => {
  return axios.patch(`/client/enable-client/${data}`);
};

//disable client
export const disableClient = (data) => {
  return axios.patch(`/client/disable-client/${data}`);
};

//add client team member
export const addClientTeamMember = (data) => {
  return axios.post(
    `/client/add-client-team-member/${data.adminId}/${data.clientId}`,
    data.data
  );
};

//edit client team member
export const editClientTeamMember = (data) => {
  return axios.patch(`/client/edit-client-team-member/${data.id}`, data.data);
};

//send invitation
export const sendClientInvitation = (data) => {
  return axios.patch(`/client/send-invitation/${data.id}`, data.data);
};

// //enable team member
// export const enableTeamMember = (data) => {
//   return axios.patch(`/client/enable-member/${data}`);
// };

// //disable team member
// export const disableTeamMember = (data) => {
//   return axios.patch(`/client/disable-member/${data}`);
// };

//delete client team member
export const deleteTeamMember = (data) => {
  return axios.delete(`/client/delete-member/${data}`);
};

export const getClientMemberLogs = (id) => {
  return axios.get(`/client/get-client-member-logs/${id}`);
};
