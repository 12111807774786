import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import adminReducer from "./admin/reducer";
import teamReducer from "./teams/reducer";
import clientReducer from "./clients/reducer";
import workStreamReducer from "./workStream/reducer";
import activityReducer from "./activities/reducer";
import filterDataReducer from "./FilterData/reducer";
import ReportingReducer from "./Reporting/reducer";
import TemplateReducer from "./templates/reducer";
import PMOReducer from "./pmo/reducer";
import SocketReducer from "./socket/reducer";

const rootReducers = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  teams: teamReducer,
  clients: clientReducer,
  workStream: workStreamReducer,
  activities: activityReducer,
  filterData: filterDataReducer,
  reporting: ReportingReducer,
  templates: TemplateReducer,
  pmo: PMOReducer,
  socket: SocketReducer,
});

export default rootReducers;
