import axios from "../../api/axiosConfig";

//get configuration
export const getConfiguration = (data) => {
  return axios.get(`/admin/get-configuration/${data}`);
};

//add configuration
export const addConfiguration = (data) => {
  return axios.post(`/admin/post-configuration/${data.id}`, data.data);
};

//get roles
export const getRoles = (data) => {
  return axios.get(`/admin/get-roles/${data}`);
};

//add role
export const addRole = (data) => {
  return axios.post(`/admin/add-role/${data.id}`, data.data);
};

//update role
export const editRole = (data) => {
  return axios.patch(`/admin/update-role/${data.id}`, data.data);
};

//delete role
export const deleteRole = (data) => {
  return axios.delete(`/admin/delete-role/${data}`);
};

//get members by role
export const getMembersByRole = (data) => {
  return axios.get(`/admin/get-members-byRole/${data.id}/${data.adminId}`);
};

//get invoices
export const getInvoices = (data) => {
  return axios.get(`/stripe/get-customer-invoices/${data}`);
};

// create usage
export const createUaage = (data) => {
  return axios.patch(`/stripe/update-subscription`, data);
};

//get members
export const getAllMembers = (data) => {
  return axios.get(`/admin/get-all-members/${data}`);
};
