import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { TeamAPI } from "../../api";
import { useSelector } from "react-redux";

function ProtectedMenu({ children }) {
  const { user } = useSelector((state) => state.auth);

  if (!user?.systemRole?.menu?.includes("teams")) {
    return <Navigate to="/auth" replace />;
  }

  return children;
}

export default ProtectedMenu;
