import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  AddressElement,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./style.css";
import { Button, Form, Input, Spin } from "antd";
import { addCustomerCard } from "../../redux/auth/actionCreator";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddCardForm = ({ role, title }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [promoCode, setPromoCode] = useState("");
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  const [city, setCity] = useState(null);
  const [address, setAddress] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);

  const { customerId, loading } = useSelector((state) => state.auth);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      let email = JSON.parse(localStorage.getItem("email"));

      let result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: name && name,
          address: {
            line1: address && address,
            city: city,
            state: state && state,
            postal_code: postalCode && postalCode,
            country: country && country,
          },
          email: email,
        },
      });

      dispatch(
        addCustomerCard({
          customerId: customerId,
          paymentMethod: result,
          promoCode: promoCode,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleAddress = (e) => {
    console.log(e.value);
    setName(e.value.name);
    setCity(e.value.address.city);
    setPostalCode(e.value.address.postal_code);
    setAddress(e.value.address.line1);
    setState(e.value.address.state);
    setCountry(e.value.address.country);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        backgroundColor: "white",
        "::placeholder": {
          color: "#aab7c4",
        },
        height: "50px", // Adjust the height as needed
        padding: "30px", // Adjust the padding as needed
      },

      invalid: {
        color: "#9e2146",
      },
    },
    hidePostalCode: false,
    iconStyle: "solid",
  };

  const paymentElementOptions = {
    mode: "shipping",
    blockPoBox: true,
    defaultValues: {
      address: {
        Address: "sdlk lsdkv",
      },
    },
  };

  return (
    <div className="card-element">
      <form onSubmit={handleSubmit}>
        <div className="card-info">
          {role == "admin" ? (
            <div>
              <div style={{ textAlign: "center", fontSize: "22px" }}>
                {title}
              </div>
              <CardElement
                options={cardElementOptions}
                className="custom-card-number-class"
              />

              <AddressElement
                options={paymentElementOptions}
                onChange={onHandleAddress}
              />
              <div style={{ width: "50%", margin: "15px auto 15px auto" }}>
                <Input
                  type="text"
                  placeholder="Enter Promo Code"
                  onChange={(e) => setPromoCode(e.target.value)}
                />
              </div>

              <div style={{ textAlign: "center" }}>
                {!loading ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!stripe}
                    className="add-card-btn"
                  >
                    Save Billing Information
                  </Button>
                ) : (
                  <Spin />
                )}
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <h2>
                Please contact your system administrator to update payment
                method.
              </h2>
            </div>
          )}

          <div style={{ textAlign: "center", margin: "12px 0px" }}>
            <div>
              Subscriptions are paid at the start of each month based on enabled
              modules and the number of users at the start of each month. The
              start of the month is determined by the date of which you first
              signed up.
            </div>
            <div>Taxes & Fees may apply</div>
          </div>
        </div>
        <div
          style={{
            color: "#565051",
            textAlign: "center",
            fontSize: "26px",
          }}
        >
          <div>Powered by Stripe.com</div>
        </div>
      </form>
    </div>
  );
};

function App({ isModalOpen, role, closeable, setModalOpen, title }) {
  return (
    <Modal
      open={isModalOpen}
      footer={null}
      closable={closeable}
      onCancel={() => setModalOpen(false)}
    >
      <Elements stripe={stripePromise}>
        <AddCardForm role={role} title={title} />
      </Elements>
    </Modal>
  );
}

export default App;
