import * as types from "./types";

const initState = {
  //   chatContacts: [],
  // addMessage: {},
  conversations: [],
  allActiveUsers: [],
  //   allMessages: [],
  //   newGroup: {},
  // deleteGroup: {},
  //   leaveGroup: {},
  loading: false,
  success: false,
  socket: null,
};

const socketReducer = (state = initState, action) => {
  switch (action.type) {
    //active-users-request
    case types.ACTIVE_USERS_REQUEST:
      return {
        ...state,
      };
    case types.ACTIVE_USERS_SUCCESS:
      return {
        ...state,
        success: true,
        allActiveUsers: action.payload,
      };
    case types.ACTIVE_USERS_FAILURE:
      return {
        ...state,
      };

    //sockets-request
    case types.SOCKET_REQUEST:
      return {
        ...state,
      };
    case types.SOCKET_SUCCESS:
      return {
        ...state,
        success: true,
        socket: action.payload,
      };
    case types.SOCKET_FAILURE:
      return {
        ...state,
      };

    //all-conversations
    case types.GET_ALL_CONVERSATIONS_REQUEST:
      return {
        ...state,
      };
    case types.GET_ALL_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        success: true,
        conversations: action.payload,
      };
    case types.GET_ALL_CONVERSATIONS_FAILURE:
      return {
        ...state,
      };

    //edit-conversations
    case types.EDIT_CONVERSATIONS_REQUEST:
      return {
        ...state,
      };
    case types.EDIT_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        success: true,
        conversations: state?.conversations?.map((el) => {
          return el?._id == action.payload ? { ...el, unRead: 0 } : el;
        }),
      };
    case types.EDIT_CONVERSATIONS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default socketReducer;
