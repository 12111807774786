import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["auth", "email"], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware, thunk];

const store = createStore(
  pReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWares))
);
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { persistor, store };

// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './rootReducers';
// // (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
// const store = createStore(rootReducer, compose(applyMiddleware(thunk.withExtraArgument())));

// export default store;
