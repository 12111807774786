import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedMenu({ children }) {
  const { user } = useSelector((state) => state.auth);
  const { configuration } = useSelector((state) => state.admin);

  if (
    configuration?.adminId?.pmo == false ||
    !user?.systemRole?.menu?.includes("pmo")
  ) {
    return <Navigate to="/admin" replace />;
  }

  return children;
}

export default ProtectedMenu;
