import * as types from "./types";
import { insertAndIncrement } from "../../utils/helpers";
const initState = {
  loading: false,
  data: [],
  success: false,
  activityLogs: [],
  row: null,
  //   myActivities: [],
  fileMedia: [],
  imageMedia: [],
  //   cardData: {},
  confetti: false,
  mediaData: null,
  archivedActivities: [],
};

const activitiesReducer = (state = initState, action) => {
  switch (action.type) {
    //get activities
    case types.GET_PMO_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_PMO_ACTIVITIES_SUCCESS:
      //;
      let array1 = [];
      action.payload?.map((item, index) => {
        let result = state?.data?.find((elem) => elem?._id == item?._id);
        if (!result) {
          array1?.push(item);
        }
      });
      //;
      return {
        ...state,
        loading: false,
        success: true,
        data: [...state.data, ...array1],
      };
    case types.GET_PMO_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get activities
    case types.GET_ACTIVITIES_BY_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_ACTIVITIES_BY_TEMPLATES_SUCCESS:
      let array2 = [];
      action.payload?.map((item, index) => {
        let result = state?.data?.find((elem) => elem?._id == item?._id);
        let result2 = array2?.find((elem) => elem?._id == item?._id);
        if (!result && !result2) {
          array2?.push(item);
        }
      });
      return {
        ...state,
        loading: false,
        success: true,
        data: [...state.data, ...array2],
      };
    case types.GET_ACTIVITIES_BY_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get archived activities
    case types.GET_PMO_ARCHIVE_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_PMO_ARCHIVE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        archivedActivities: action.payload,
      };
    case types.GET_PMO_ARCHIVE_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add activity
    case types.POST_PMO_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.POST_PMO_ACTIVITY_SUCCESS:
      //;
      if (action.payload.targetIndex) {
        let newData = [...state.data];

        // Insert the new object at the specified index
        newData = newData?.map((elem) =>
          elem?.index == action.payload.targetIndex ||
          elem?.index > action.payload.targetIndex
            ? { ...elem, index: elem?.index + 1 }
            : elem
        );
        // console.log(newData);
        // //;

        // Update the index field of the remaining objects
        // newData = newData.forEach((item, index) => {
        //   item.index = index + 1;
        // });
        // //;
        return {
          ...state,
          loading: false,
          success: true,
          data: [...newData, action.payload.data],
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          data: [...state.data, action.payload.data],
        };
      }
    case types.POST_PMO_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //convert type
    case types.CONVERT_PMO_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CONVERT_PMO_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data?.map((elem) =>
          elem?._id == action.payload._id ? action.payload : elem
        ),
        // myActivities: state.myActivities?.map((elem) =>
        //   elem?._id == action.payload._id ? action.payload : elem
        // ),
      };
    case types.CONVERT_PMO_TYPE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete activity
    case types.DELETE_PMO_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_PMO_ACTIVITY_SUCCESS:
      let res = state?.data?.filter((elem) => elem?._id !== action.payload._id);
      res = res?.map((elem) =>
        elem?.index > action.payload.index
          ? { ...elem, index: elem.index - 1 }
          : elem
      );
      return {
        ...state,
        loading: false,
        success: true,
        data: res,
      };
    case types.DELETE_PMO_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit activity
    case types.EDIT_PMO_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_PMO_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        confetti: action.payload?.confetti,
        data: state?.data?.map((elem) =>
          elem?._id == action.payload.data?._id ? action.payload?.data : elem
        ),
        // myActivities: state?.myActivities?.map((elem) =>
        //   elem?._id == action.payload?.data?._id ? action.payload?.data : elem
        // ),
        // cardData: action.payload?.data,
      };
    case types.EDIT_PMO_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add activity log
    case types.ADD_PMO_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_PMO_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case types.ADD_PMO_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get activity logs
    case types.GET_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        activityLogs: action.payload,
      };
    case types.GET_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //filter activities
    case types.FILTER_PMO_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.FILTER_PMO_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state?.data?.filter(
          (elem) => elem?.template?._id !== action.payload
        ),
      };
    case types.FILTER_PMO_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    // swap activities
    case types.SWAP_PMO_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.SWAP_PMO_ACTIVITIES_SUCCESS:
      //;
      let obj1 = action.payload.activeObj;
      let obj2 = action.payload.overObj;
      let arrResult = state?.data?.map((elem) => {
        if (elem._id == obj1._id) {
          return { ...elem, index: obj1.index };
        } else if (elem._id == obj2._id) {
          return { ...elem, index: obj2.index };
        } else {
          return elem;
        }
      });

      //;

      return {
        ...state,
        loading: false,
        success: true,
        data: arrResult,
      };
    case types.SWAP_PMO_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    // //clear table
    // case types.CLEAR_TABLE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.CLEAR_TABLE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     data: [],
    //     activityLogs: [],
    //     myActivities: [],
    //   };
    // case types.CLEAR_TABLE_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //clear table
    // case types.GET_CLIENT_ACT_REQUEST:
    //   //;
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.GET_CLIENT_ACT_SUCCESS:
    //   //;
    //   let array = [];
    //   action.payload?.map((item) => {
    //     let result = state?.data?.find((elem) => elem?._id == item?._id);
    //     if (!result) {
    //       array?.push(item);
    //     }
    //   });
    //   //;
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     data: [...state.data, ...array],
    //   };
    // case types.GET_CLIENT_ACT_FAILURE:
    //   //;
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //upload media
    // case types.UPLOAD_MEDIA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.UPLOAD_MEDIA_SUCCESS:
    //   //;
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     data: state.data?.map((item) =>
    //       item?._id == action.payload._id ? action.payload : item
    //     ),
    //     myActivities: state.myActivities?.map((item) =>
    //       item?._id == action.payload._id ? action.payload : item
    //     ),
    //     cardData: action.payload,
    //   };
    // case types.UPLOAD_MEDIA_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //my-activities
    // case types.MY_ACTIVITIES_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.MY_ACTIVITIES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     myActivities: action.payload,
    //   };
    // case types.MY_ACTIVITIES_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //get-media
    // case types.GET_MEDIA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.GET_MEDIA_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     fileMedia: action.payload.filter((el) => el.type == "file"),
    //     imageMedia: action.payload.filter((el) => el.type == "image"),
    //   };
    // case types.GET_MEDIA_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //delete-media
    // case types.DELETE_MEDIA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.DELETE_MEDIA_SUCCESS:
    //   let inputsData, outputsData, processData;
    //   if (action.payload.table == "inputs") {
    //     inputsData = state?.data?.find(
    //       (elem) => elem?._id == action.payload.data.activityId
    //     ).expandedRow.inputs;
    //     //;
    //     inputsData = inputsData?.map((elem) =>
    //       elem._id == action.payload.data._id ? action.payload.data : elem
    //     );
    //   } else if (action.payload.table == "outputs") {
    //     outputsData = state?.data?.find(
    //       (elem) => elem?._id == action.payload.data.activityId
    //     ).expandedRow.outputs;
    //     //;
    //     outputsData = outputsData?.map((elem) =>
    //       elem._id == action.payload.data._id ? action.payload.data : elem
    //     );
    //   } else if (action.payload.table == "process") {
    //     processData = state?.data?.find(
    //       (elem) => elem?._id == action.payload.data.activityId
    //     ).expandedRow.outputs;
    //     //;
    //     processData = processData?.map((elem) =>
    //       elem._id == action.payload.data._id ? action.payload.data : elem
    //     );
    //   }
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     fileMedia: state.fileMedia.filter(
    //       (el) => el._id !== action.payload._id
    //     ),
    //     imageMedia: state.imageMedia.filter(
    //       (el) => el._id !== action.payload._id
    //     ),
    //     data: state.data?.map((elem) =>
    //       elem._id == action.payload.data.activityId
    //         ? {
    //             ...elem,
    //             expandedRow: {
    //               ...elem.expandedRow,
    //               inputs:
    //                 action.payload.table == "inputs"
    //                   ? inputsData
    //                   : elem.expandedRow.inputs,
    //               outputs:
    //                 action.payload.table == "outputs"
    //                   ? outputsData
    //                   : elem.expandedRow.outputs,
    //               process:
    //                 action.payload.table == "process"
    //                   ? processData
    //                   : elem.expandedRow.process,
    //             },
    //           }
    //         : elem
    //     ),
    //   };
    // case types.DELETE_MEDIA_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //get-media-by-id
    // case types.GET_MEDIA_BYID_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.GET_MEDIA_BYID_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     mediaData: action.payload,
    //   };
    // case types.GET_MEDIA_BYID_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //get-media
    // case types.SET_CARD_DATA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.SET_CARD_DATA_SUCCESS:
    //   //;
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     cardData: action.payload,
    //   };
    // case types.SET_CARD_DATA_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    default:
      return state;
  }
};

export default activitiesReducer;
