import axios from "../axiosConfig";

class Routes {
  login(data) {
    return axios.post("/auth/login", data);
  }
  signup(data) {
    return axios.post("/auth/signup", data);
  }
  signUpWithGoogle(data) {
    return axios.post("/auth/signup-with-google", data);
  }
  resetPassword(data) {
    return axios.patch(`/auth/resetPassword`, data);
  }
  forgetPassword(data) {
    return axios.post(`/auth/forgetPassword`, data);
  }
  emailVerification(data) {
    return axios.patch(`/auth/emailVerification`, data);
  }
  resendVerification(userId) {
    return axios.patch(`/auth/resendVerification/${userId}`);
  }
}

export default new Routes();
