export const ADMIN_ACTIVITIES_REQUEST = "ADMIN_ACTIVITIES_REQUEST";
export const ADMIN_ACTIVITIES_SUCCESS = "ADMIN_ACTIVITIES_SUCCESS";
export const ADMIN_ACTIVITIES_FAILURE = "ADMIN_ACTIVITIES_FAILURE";

export const GET_ALL_WORKSTREAMS_REQUEST = "GET_ALL_WORKSTREAMS_REQUEST";
export const GET_ALL_WORKSTREAMS_SUCCESS = "GET_ALL_WORKSTREAMS_SUCCESS";
export const GET_ALL_WORKSTREAMS_FAILURE = "GET_ALL_WORKSTREAMS_FAILURE";

export const GET_TEAM_MEMBERS_REQUEST = "GET_TEAM_MEMBERS_REQUEST";
export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS";
export const GET_TEAM_MEMBERS_FAILURE = "GET_TEAM_MEMBERS_FAILURE";

export const GET_GRAPH_REQUEST = "GET_GRAPH_REQUEST";
export const GET_GRAPH_SUCCESS = "GET_GRAPH_SUCCESS";
export const GET_GRAPH_FAILURE = "GET_GRAPH_FAILURE";

export const GET_GRAPH_ACTIVITY_REQUEST = "GET_GRAPH_ACTIVITY_REQUEST";
export const GET_GRAPH_ACTIVITY_SUCCESS = "GET_GRAPH_ACTIVITY_SUCCESS";
export const GET_GRAPH_ACTIVITY_FAILURE = "GET_GRAPH_ACTIVITY_FAILURE";
