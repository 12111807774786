import * as types from "./types";

const initState = {
  user: null,
  loading: false,
  token: null,
  codeModal: false,
  userId: null,
  paymentId: null,
  login: false,
  success: false,
  reset: false,
  SignUp: false,
  state: null,
  verify: false,
  customerId: null,
  Oauth: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    //get user
    case types.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload === null ? {} : action.payload,
        login: true,
      };
    case types.GET_USER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get single member
    case types.SINGLE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.SINGLE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload.data,
      };
    case types.SINGLE_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //update user
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //login user
    case types.GET_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_LOGIN_SUCCESS:
      if (action.payload?.response?.status == 401) {
        return {
          ...state,
          codeModal: true,
          userId: action.payload?.response?.data?.userId,
        };
      } else if (action.payload?.status == 200) {
        //;
        return {
          ...state,
          loading: false,
          success: true,
          login: true,
          user: action.payload?.data?.data,
          token: action.payload?.data?.token,
        };
      }
    case types.GET_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };

    //SignUp user
    case types.SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SIGNUP_SUCCESS:
      //;
      localStorage.setItem("user", JSON.stringify(action.payload.data.data));
      return {
        ...state,
        loading: false,
        success: true,
        codeModal: true,
        userId: action.payload.data.data._id,
      };
    case types.SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };

    //login with googlw
    case types.GOOGLE_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        login: true,
        user: action.payload?.data,
        token: action.payload?.token,
        Oauth: action.payload?.Oauth,
      };
    case types.GOOGLE_AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };

    //logout request
    case types.LOGOUT_REQUEST:
      return {
        ...state,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        loading: false,
        token: null,
        codeModal: false,
        userId: null,
        paymentId: null,
        login: false,
        success: false,
        reset: false,
        SignUp: false,
        state: null,
        verify: false,
        Oauth: null,
        customerId: null,
      };
    case types.LOGOUT_FAILURE:
      return {
        ...state,
      };

    //Code Modal request
    case types.CODE_MODAL_REQUEST:
      return {
        ...state,
      };
    case types.CODE_MODAL_SUCCESS:
      return {
        ...state,
        codeModal: false,
      };
    case types.CODE_MODAL_FAILURE:
      return {
        ...state,
      };

    //email verification
    case types.EMAIL_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.EMAIL_VERIFY_SUCCESS:
      if (
        action?.payload?.status == 201 &&
        action.payload.data.data.plan == "growth"
      ) {
        debugger;
        localStorage.setItem(
          "role",
          JSON.stringify(action.payload?.data?.data?.role)
        );
        return {
          ...state,
          codeModal: false,
          success: true,
          SignUp: true,
          userId: action.payload?.data?.data?._id,
          customerId: action.payload?.data?.data?.stripe_CustomerId,
          token: action.payload?.data?.token,
          loading: false,
        };
      } else if (action?.payload?.status == 201) {
        localStorage.setItem(
          "user",
          JSON.stringify(action.payload?.data?.data)
        );
        window.location.href = "/activities";
        return {
          ...state,
          codeModal: false,
          success: true,
          SignUp: true,
          login: true,
          user: action.payload?.data?.data,
          customerId: action.payload?.data?.data?.stripe_CustomerId,
          token: action.payload?.data?.token,
          loading: false,
        };
      }

    case types.EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };

    //add-customer-card
    case types.ADD_CUSTOMER_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_CUSTOMER_CARD_SUCCESS:
      return {
        ...state,
        paymentId: action.payload?.paymentId,
        user: action.payload?.user,
        loading: false,
      };

    case types.ADD_CUSTOMER_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };

    //forget password
    case types.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FORGET_PASSWORD_SUCCESS:
      if (action.payload.status == 200) {
        return {
          ...state,
          success: true,
          loading: false,
          reset: false,
        };
      } else {
        return {
          ...state,
          success: false,
          loading: false,
        };
      }
    case types.FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
      };

    //reset password
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RESET_PASSWORD_SUCCESS:
      if (action.payload.status == 200) {
        return {
          ...state,
          success: true,
          loading: false,
          reset: true,
        };
      } else {
        return {
          ...state,
          success: false,
          loading: false,
        };
      }
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
      };

    //resend verification
    case types.RESEND_VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RESEND_VERIFY_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case types.RESEND_VERIFY_FAILURE:
      return {
        ...state,
        loading: false,
      };

    //success false request
    case types.STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.STATUS_SUCCESS:
      return {
        ...state,
        success: false,
        loading: false,
      };

    //boarding signup
    case types.BOARDING_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BOARDING_SIGNUP_SUCCESS:
      //;
      return {
        ...state,
        success: true,
        loading: false,
      };
    case types.BOARDING_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
      };

    //boarding signup
    case types.CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CONTACT_US_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case types.CONTACT_US_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case types.MODIFY_CONFETTI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.MODIFY_CONFETTI_SUCCESS:
      //;

      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
      };
    case types.MODIFY_CONFETTI_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case types.MODIFY_FIRST_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.MODIFY_FIRST_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
      };
    case types.MODIFY_FIRST_LOGIN_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default authReducer;
