/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get activities
function* getActivitiesSaga(action) {
  try {
    const result = yield call(api.getActivities, action.payload);
    yield put({
      type: types.GET_PMO_ACTIVITIES_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_PMO_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//add activity
function* addActivitySaga(action) {
  try {
    const result = yield call(api.addActivity, action.payload);
    yield put({
      type: types.POST_PMO_ACTIVITY_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.POST_PMO_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

//convert type
function* convertTypeSaga(action) {
  try {
    const result = yield call(api.convertType, action.payload);
    yield put({
      type: types.CONVERT_PMO_TYPE_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.CONVERT_PMO_TYPE_FAILURE,
      payload: error,
    });
  }
}

//delete activity
function* deleteActivitySaga(action) {
  try {
    const result = yield call(api.deleteActivity, action.payload);
    yield put({
      type: types.DELETE_PMO_ACTIVITY_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_PMO_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

//edit activity
function* editActivitySaga(action) {
  try {
    const result = yield call(api.editActivity, action.payload);
    yield put({
      type: types.EDIT_PMO_ACTIVITY_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_PMO_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

//add log
function* addLogSaga(action) {
  try {
    const result = yield call(api.addLog, action.payload);
    yield put({
      type: types.ADD_PMO_LOG_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.ADD_PMO_LOG_FAILURE,
      payload: error,
    });
  }
}

//get logs
function* getLogsSaga(action) {
  try {
    const result = yield call(api.getLogs, action.payload);
    yield put({
      type: types.GET_LOG_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_LOG_FAILURE,
      payload: error,
    });
  }
}

//filter activities
function* filterActivities(action) {
  try {
    yield put({
      type: types.FILTER_PMO_ACTIVITY_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.FILTER_PMO_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

// //clear table
// function* clearTable() {
//   try {
//     yield put({
//       type: types.CLEAR_TABLE_SUCCESS,
//     });
//   } catch (error) {
//     yield put({
//       type: types.CLEAR_TABLE_FAILURE,
//       payload: error,
//     });
//   }
// }

// //clear table
// function* get_ActivityClient(action) {
//   try {
//     const result = yield call(api.getActivityClient, action.payload);
//     yield put({
//       type: types.GET_CLIENT_ACT_SUCCESS,
//       payload: result.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.GET_CLIENT_ACT_FAILURE,
//       payload: error,
//     });
//   }
// }

// //upload-media
// function* uploadMedia(action) {
//   try {
//     const result = yield call(api.uploadMedia, action.payload);
//     yield put({
//       type: types.UPLOAD_MEDIA_SUCCESS,
//       payload: result.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.UPLOAD_MEDIA_FAILURE,
//       payload: error,
//     });
//   }
// }

// //delete-media
// function* deleteMedia(action) {
//   try {
//     const result = yield call(api.deleteMedia, action.payload);
//     yield put({
//       type: types.DELETE_MEDIA_SUCCESS,
//       payload: result.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.DELETE_MEDIA_FAILURE,
//       payload: error,
//     });
//   }
// }

// //my-activities
// function* myActivities(action) {
//   try {
//     const result = yield call(api.myActivities, action.payload);
//     yield put({
//       type: types.MY_ACTIVITIES_SUCCESS,
//       payload: result.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.MY_ACTIVITIES_FAILURE,
//       payload: error,
//     });
//   }
// }

// //get-media
// function* getMedia(action) {
//   try {
//     const result = yield call(api.getMedia, action.payload);
//     yield put({
//       type: types.GET_MEDIA_SUCCESS,
//       payload: result.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.GET_MEDIA_FAILURE,
//       payload: error,
//     });
//   }
// }

// //get-media
// function* getMediaById(action) {
//   try {
//     const result = yield call(api.getMediaById, action.payload);
//     //;
//     yield put({
//       type: types.GET_MEDIA_BYID_SUCCESS,
//       payload: result.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.GET_MEDIA_BYID_FAILURE,
//       payload: error,
//     });
//   }
// }

// //get-media
// function* setCardData(action) {
//   try {
//     yield put({
//       type: types.SET_CARD_DATA_SUCCESS,
//       payload: action.payload,
//     });
//   } catch (error) {
//     yield put({
//       type: types.SET_CARD_DATA_FAILURE,
//       payload: error,
//     });
//   }
// }

//get archived activities
function* getArchivedActivities(action) {
  try {
    const result = yield call(api.getArchivedActivities, action.payload);
    yield put({
      type: types.GET_PMO_ARCHIVE_ACTIVITIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_PMO_ARCHIVE_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

//get activities by templates
function* getActivitiesByTemplates(action) {
  try {
    const result = yield call(api.getActivitiesByTemplates, action.payload);
    yield put({
      type: types.GET_ACTIVITIES_BY_TEMPLATES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ACTIVITIES_BY_TEMPLATES_FAILURE,
      payload: error,
    });
  }
}

//swap pmo activities
function* swap_PMO_Activities(action) {
  try {
    const result = yield call(api.swapPMOActivities, action.payload);
    yield put({
      type: types.SWAP_PMO_ACTIVITIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.SWAP_PMO_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

export function* pmoActivitiesWatcher() {
  yield takeLatest(types.GET_PMO_ACTIVITIES_REQUEST, getActivitiesSaga);
  yield takeLatest(types.POST_PMO_ACTIVITY_REQUEST, addActivitySaga);
  yield takeLatest(types.CONVERT_PMO_TYPE_REQUEST, convertTypeSaga);
  yield takeLatest(types.DELETE_PMO_ACTIVITY_REQUEST, deleteActivitySaga);
  yield takeLatest(types.EDIT_PMO_ACTIVITY_REQUEST, editActivitySaga);
  yield takeLatest(types.ADD_PMO_LOG_REQUEST, addLogSaga);
  yield takeLatest(types.GET_LOG_REQUEST, getLogsSaga);
  yield takeLatest(types.FILTER_PMO_ACTIVITY_REQUEST, filterActivities);
  yield takeLatest(types.SWAP_PMO_ACTIVITIES_REQUEST, swap_PMO_Activities);
  yield takeLatest(
    types.GET_ACTIVITIES_BY_TEMPLATES_REQUEST,
    getActivitiesByTemplates
  );
  yield takeLatest(
    types.GET_PMO_ARCHIVE_ACTIVITIES_REQUEST,
    getArchivedActivities
  );
  //   yield takeLatest(types.CLEAR_TABLE_REQUEST, clearTable);
  //   yield takeLatest(types.GET_CLIENT_ACT_REQUEST, get_ActivityClient);
  //   yield takeLatest(types.UPLOAD_MEDIA_REQUEST, uploadMedia);
  //   yield takeLatest(types.MY_ACTIVITIES_REQUEST, myActivities);
  //   yield takeLatest(types.GET_MEDIA_REQUEST, getMedia);
  //   yield takeLatest(types.SET_CARD_DATA_REQUEST, setCardData);
  //   yield takeLatest(types.GET_MEDIA_BYID_REQUEST, getMediaById);
  //   yield takeLatest(types.DELETE_MEDIA_REQUEST, deleteMedia);
}
