import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedCancelSubs({ children }) {
  const { user } = useSelector((state) => state.auth);
  const [state, setState] = React.useState();

  React.useEffect(() => {
    if (user) setState(user);
  }, [user]);

  if (user && user.role !== "admin") {
    return <Navigate to="/profile" replace />;
  }

  return children;
}

export default ProtectedCancelSubs;
