import axios from "../axiosConfig";

class Routes {
  getConfiguration(adminId) {
    return axios.get(`/admin/get-configuration/${adminId}`);
  }

  postConfiguration(adminId, data) {
    return axios.post(`/admin/post-configuration/${adminId}`, data);
  }

  getRoles(adminId) {
    return axios.get(`/admin/get-roles/${adminId}`);
  }

  addRole(adminId, data) {
    return axios.post(`/admin/add-role/${adminId}`, data);
  }

  updateRole(id, data) {
    return axios.patch(`/admin/update-role/${id}`, data);
  }

  deleteRole(id) {
    return axios.delete(`/admin/delete-role/${id}`);
  }

  getCustomerPortal(id) {
    return axios.get(`/stripe/get-customer-portal/${id}`);
  }
}

export default new Routes();
