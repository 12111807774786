import * as types from "./types";

export const getConfiguration = (data) => {
  return {
    type: types.GET_CONFIGURATION_REQUEST,
    payload: data,
  };
};

export const postConfiguration = (id, data) => {
  return {
    type: types.POST_CONFIGURATION_REQUEST,
    payload: { id, data },
  };
};

export const getRoles = (data) => {
  return {
    type: types.GET_ROLE_REQUEST,
    payload: data,
  };
};

export const addRole = (id, data) => {
  return {
    type: types.ADD_ROLE_REQUEST,
    payload: { id, data },
  };
};

export const editRole = (id, data) => {
  return {
    type: types.EDIT_ROLE_REQUEST,
    payload: { id, data },
  };
};

export const deleteRole = (data) => {
  return {
    type: types.DELETE_ROLE_REQUEST,
    payload: data,
  };
};

export const getMembersByRole = (data) => {
  return {
    type: types.ROLE_MEMBERS_REQUEST,
    payload: data,
  };
};

export const filterMembersRole = (data) => {
  return {
    type: types.FILTER_MEMBERS_REQUEST,
    payload: data,
  };
};

export const clearConfig = () => {
  return {
    type: types.CLEAR_CONFIG_REQUEST,
  };
};

export const getInvoices = (data) => {
  return {
    type: types.GET_INVOICES_REQUEST,
    payload: data,
  };
};

export const createUsage = (data) => {
  return {
    type: types.CREATE_USAGE_REQUEST,
    payload: data,
  };
};

export const getAllMembers = (data) => {
  return {
    type: types.GET_MEMBERS_REQUEST,
    payload: data,
  };
};
