// //  eslint-disable
// export const GET_CHAT_CONTACTS_REQUEST = "GET_CHAT_CONTACTS_REQUEST";
// export const GET_CHAT_CONTACTS_SUCCESS = "GET_CHAT_CONTACTS_SUCCESS";
// export const GET_CHAT_CONTACTS_FAILURE = "GET_CHAT_CONTACTS_FAILURE";

// export const ADD_MESSAGE_REQUEST = "ADD_MESSAGE_REQUEST";
// export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";
// export const ADD_MESSAGE_FAILURE = "ADD_MESSAGE_FAILURE";

// export const GET_CONVERSATION_REQUEST = "GET_CONVERSATION_REQUEST";
// export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
// export const GET_CONVERSATION_FAILURE = "GET_CONVERSATION_FAILURE";

export const GET_ALL_CONVERSATIONS_REQUEST = "GET_ALL_CONVERSATIONS_REQUEST";
export const GET_ALL_CONVERSATIONS_SUCCESS = "GET_ALL_CONVERSATIONS_SUCCESS";
export const GET_ALL_CONVERSATIONS_FAILURE = "GET_ALL_CONVERSATIONS_FAILURE";

export const EDIT_CONVERSATIONS_REQUEST = "EDIT_CONVERSATIONS_REQUEST";
export const EDIT_CONVERSATIONS_SUCCESS = "EDIT_CONVERSATIONS_SUCCESS";
export const EDIT_CONVERSATIONS_FAILURE = "EDIT_CONVERSATIONS_FAILURE";

// export const GET_CONVERSATION_BY_ID_REQUEST = "GET_CONVERSATION_BY_ID_REQUEST";
// export const GET_CONVERSATION_BY_ID_SUCCESS = "GET_CONVERSATION_BY_ID_SUCCESS";
// export const GET_CONVERSATION_BY_ID_FAILURE = "GET_CONVERSATION_BY_ID_FAILURE";

// export const CREATE_GROUP_CONVERSATION_REQUEST =
//   "CREATE_GROUP_CONVERSATION_REQUEST";
// export const CREATE_GROUP_CONVERSATION_SUCCESS =
//   "CREATE_GROUP_CONVERSATION_SUCCESS";
// export const CREATE_GROUP_CONVERSATION_FAILURE =
//   "CREATE_GROUP_CONVERSATION_FAILURE";

// export const GET_GROUP_CONVERSATION_REQUEST = "GET_GROUP_CONVERSATION_REQUEST";
// export const GET_GROUP_CONVERSATION_SUCCESS = "GET_GROUP_CONVERSATION_SUCCESS";
// export const GET_GROUP_CONVERSATION_FAILURE = "GET_GROUP_CONVERSATION_FAILURE";

// export const DELETE_GROUP_CONVERSATION_REQUEST =
//   "DELETE_GROUP_CONVERSATION_REQUEST";
// export const DELETE_GROUP_CONVERSATION_SUCCESS =
//   "DELETE_GROUP_CONVERSATION_SUCCESS";
// export const DELETE_GROUP_CONVERSATION_FAILURE =
//   "DELETE_GROUP_CONVERSATION_FAILURE";

// export const SEEN_MESSAGE_REQUEST = "SEEN_MESSAGE_REQUEST";
// export const SEEN_MESSAGE_SUCCESS = "SEEN_MESSAGE_SUCCESS";
// export const SEEN_MESSAGE_FAILURE = "SEEN_MESSAGE_FAILURE";

// export const LIVE_SEEN_MESSAGE_REQUEST = "LIVE_SEEN_MESSAGE_REQUEST";
// export const LIVE_SEEN_MESSAGE_SUCCESS = "LIVE_SEEN_MESSAGE_SUCCESS";
// export const LIVE_SEEN_MESSAGE_FAILURE = "LIVE_SEEN_MESSAGE_FAILURE";

export const ACTIVE_USERS_REQUEST = "ACTIVE_USERS_REQUEST";
export const ACTIVE_USERS_SUCCESS = "ACTIVE_USERS_SUCCESS";
export const ACTIVE_USERS_FAILURE = "ACTIVE_USERS_FAILURE";

export const SOCKET_REQUEST = "SOCKET_REQUEST";
export const SOCKET_SUCCESS = "SOCKET_SUCCESS";
export const SOCKET_FAILURE = "SOCKET_FAILURE";
