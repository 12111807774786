/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get user by specific sites
function* getUserSaga(action) {
  try {
    const result = yield call(api.getUser, action.payload);
    yield put({
      type: types.GET_USER_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_USER_FAILURE,
      payload: error,
    });
  }
}

//login user
function* loginUserSaga(action) {
  try {
    const result = yield call(api.loginUser, action.payload);
    yield put({
      type: types.GET_LOGIN_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: types.GET_LOGIN_FAILURE,
      payload: error,
    });
  }
}

//mdify user
function* modifyConfettiSaga(action) {
  try {
    const result = yield call(api.modifyConfetti, action.payload);
    //;
    yield put({
      type: types.MODIFY_CONFETTI_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.MODIFY_CONFETTI_FAILURE,
      payload: error,
    });
  }
}

//signup user
function* signupSaga(action) {
  try {
    const result = yield call(api.signUp, action.payload);
    yield put({
      type: types.SIGNUP_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: types.SIGNUP_FAILURE,
      payload: error,
    });
  }
}

//email verify
function* emailVerificationSaga(action) {
  try {
    const result = yield call(api.emailVerification, action.payload);
    yield put({
      type: types.EMAIL_VERIFY_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: types.EMAIL_VERIFY_FAILURE,
      payload: error,
    });
  }
}

//forget password
function* forgetPasswordSaga(action) {
  try {
    const result = yield call(api.forgetPassword, action.payload);
    yield put({
      type: types.FORGET_PASSWORD_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: types.FORGET_PASSWORD_FAILURE,
      payload: error,
    });
  }
}

//reset password
function* resetPasswordSaga(action) {
  try {
    const result = yield call(api.resetPassword, action.payload);
    yield put({
      type: types.RESET_PASSWORD_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: types.RESET_PASSWORD_FAILURE,
      payload: error,
    });
  }
}

//resend email verify
function* resendVerificationSaga(action) {
  try {
    const result = yield call(api.resendVerification, action.payload);
    yield put({
      type: types.RESEND_VERIFY_SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: types.RESEND_VERIFY_FAILURE,
      payload: error,
    });
  }
}

//logout user
function* logoutUserSaga(action) {
  try {
    yield put({
      type: types.LOGOUT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.LOGOUT_FAILURE,
      payload: error,
    });
  }
}

//clode code modal
function* codeModalSaga(action) {
  try {
    yield put({
      type: types.CODE_MODAL_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.CODE_MODAL_FAILURE,
      payload: error,
    });
  }
}

//google auth
function* googleAuthSaga(action) {
  try {
    const result = yield call(api.googleAuth, action.payload);
    yield put({
      type: types.GOOGLE_AUTH_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.GOOGLE_AUTH_FAILURE,
      payload: error,
    });
  }
}

//update user
function* updateUserSaga(action) {
  try {
    //;
    const result = yield call(api.updateUser, action.payload);
    yield put({
      type: types.UPDATE_USER_SUCCESS,
      payload: result.data.data,
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_USER_FAILURE,
      payload: error,
    });
  }
}

//status success
function* statusSaga() {
  try {
    yield put({
      type: types.STATUS_SUCCESS,
    });
  } catch (error) {}
}

//boarding signup
function* boardingSignupSaga(action) {
  try {
    const result = yield call(api.boardingSignup, action.payload);
    yield put({
      type: types.BOARDING_SIGNUP_SUCCESS,
      payload: result.data.data,
    });
  } catch (error) {
    yield put({
      type: types.BOARDING_SIGNUP_FAILURE,
      payload: error,
    });
  }
}

// single member
function* getMemberSaga(action) {
  try {
    const result = yield call(api.getMember, action.payload);
    yield put({
      type: types.SINGLE_MEMBER_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.SINGLE_MEMBER_FAILURE,
      payload: error,
    });
  }
}

// contact us
function* contactSaga(action) {
  try {
    const result = yield call(api.contactUs, action.payload);
    yield put({
      type: types.CONTACT_US_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.CODE_MODAL_FAILURE,
      payload: error,
    });
  }
}

// add-customer-card
function* addCustomerCard(action) {
  try {
    const result = yield call(api.addCustomerCard, action.payload);
    yield put({
      type: types.ADD_CUSTOMER_CARD_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_CUSTOMER_CARD_FAILURE,
      payload: error,
    });
  }
}

// modify-first-login
function* modifyFirstLogin(action) {
  try {
    const result = yield call(api.modifyFirstLogin, action.payload);
    yield put({
      type: types.MODIFY_FIRST_LOGIN_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.MODIFY_FIRST_LOGIN_FAILURE,
      payload: error,
    });
  }
}

export function* authWatcher() {
  yield takeLatest(types.GET_USER_REQUEST, getUserSaga);
  yield takeLatest(types.UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLatest(types.GET_LOGIN_REQUEST, loginUserSaga);
  yield takeLatest(types.SIGNUP_REQUEST, signupSaga);
  yield takeLatest(types.LOGOUT_REQUEST, logoutUserSaga);
  yield takeLatest(types.CODE_MODAL_REQUEST, codeModalSaga);
  yield takeLatest(types.EMAIL_VERIFY_REQUEST, emailVerificationSaga);
  yield takeLatest(types.RESEND_VERIFY_REQUEST, resendVerificationSaga);
  yield takeLatest(types.FORGET_PASSWORD_REQUEST, forgetPasswordSaga);
  yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(types.GOOGLE_AUTH_REQUEST, googleAuthSaga);
  yield takeLatest(types.STATUS_REQUEST, statusSaga);
  yield takeLatest(types.BOARDING_SIGNUP_REQUEST, boardingSignupSaga);
  yield takeLatest(types.SINGLE_MEMBER_REQUEST, getMemberSaga);
  yield takeLatest(types.CONTACT_US_REQUEST, contactSaga);
  yield takeLatest(types.ADD_CUSTOMER_CARD_REQUEST, addCustomerCard);
  yield takeLatest(types.MODIFY_CONFETTI_REQUEST, modifyConfettiSaga);
  yield takeLatest(types.MODIFY_FIRST_LOGIN_REQUEST, modifyFirstLogin);
}
