import axios from "../../api/axiosConfig";

//get activities by admin
export const getActivitiesByAdmin = (data) => {
  return axios.get(`/activity/get-activities-admin/${data}`);
};

//get activities by admin
export const getWorkstreamsByAdmin = (data) => {
  return axios.get(`/workStream/get-all-workstreams/${data}`);
};

//get activities by admin
export const getTeamMembersReporting = (data) => {
  return axios.post(`/team/get-team-members-reporting/${data.id}`, data.data);
};

//graph status
export const getGraphData = (data) => {
  let date = new Date().getDate();
  return axios.get(
    `/activity/get-graph-data/${data.id}/${date}/?activity=${data.activity}`
  );
};

//graph data activity
export const getGraphActivity = (data) => {
  let date = new Date().getDate();
  return axios.post(`/activity/get-graph-activity/${date}`, data);
};
