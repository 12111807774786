/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//
function* getActivitiesByAdmin(action) {
  try {
    const result = yield call(api.getActivitiesByAdmin, action.payload);
    yield put({
      type: types.ADMIN_ACTIVITIES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    yield put({
      type: types.ADMIN_ACTIVITIES_FAILURE,
      payload: error,
    });
  }
}

function* getWorkstreamsByAdmin(action) {
  try {
    const result = yield call(api.getWorkstreamsByAdmin, action.payload);
    yield put({
      type: types.GET_ALL_WORKSTREAMS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ALL_WORKSTREAMS_FAILURE,
      payload: error,
    });
  }
}

function* getTeamMembersReporting(action) {
  try {
    const result = yield call(api.getTeamMembersReporting, action.payload);
    yield put({
      type: types.GET_TEAM_MEMBERS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TEAM_MEMBERS_FAILURE,
      payload: error,
    });
  }
}

//get-graph-data
function* getGraphData(action) {
  //;
  try {
    const result = yield call(api.getGraphData, action.payload);
    yield put({
      type: types.GET_GRAPH_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_GRAPH_FAILURE,
      payload: error,
    });
  }
}

//get-graph-data-activity
function* getGraphActivitySaga(action) {
  try {
    const result = yield call(api.getGraphActivity, action.payload);
    yield put({
      type: types.GET_GRAPH_ACTIVITY_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_GRAPH_ACTIVITY_FAILURE,
      payload: error,
    });
  }
}

export function* reportingWatcher() {
  yield takeLatest(types.ADMIN_ACTIVITIES_REQUEST, getActivitiesByAdmin);
  yield takeLatest(types.GET_ALL_WORKSTREAMS_REQUEST, getWorkstreamsByAdmin);
  yield takeLatest(types.GET_TEAM_MEMBERS_REQUEST, getTeamMembersReporting);
  yield takeLatest(types.GET_GRAPH_REQUEST, getGraphData);
  yield takeLatest(types.GET_GRAPH_ACTIVITY_REQUEST, getGraphActivitySaga);
}
