import axios from "../axiosConfig";

class Routes {
  // department apis
  addDepartment(id, data) {
    return axios.post(`/team/add-department/${id}`, data);
  }

  getDepartments(id) {
    return axios.get(`/team/get-departments/${id}`);
  }

  editDepartments(id, data) {
    return axios.patch(`/team/edit-department/${id}`, data);
  }

  deleteDepartment(id) {
    return axios.delete(`/team/delete-department/${id}`);
  }

  //teams apis
  addTeam(id, data) {
    return axios.post(`/team/add-team/${id}`, data);
  }

  getTeams(id) {
    return axios.get(`/team/get-teams/${id}`);
  }

  editTeam(id, data) {
    return axios.patch(`/team/edit-team/${id}`, data);
  }

  deleteTeam(id) {
    return axios.delete(`/team/delete-team/${id}`);
  }

  //team members apis
  addTeamMember(id, data) {
    return axios.post(`/team/add-team-member/${id}`, data);
  }

  getTeamMembers(id) {
    return axios.get(`/team/get-team-members/${id}`);
  }

  getMemberById(id) {
    return axios.get(`/team/get-member-byId/${id}`);
  }

  editTeamMember(id, data) {
    return axios.patch(`/team/edit-team-member/${id}`, data);
  }

  sendInvitation(id, data) {
    return axios.patch(`/team/send-invitation/${id}`, data);
  }

  deleteTeamMember(id) {
    return axios.delete(`/team/delete-team-member/${id}`);
  }

  enableTeamMember(data) {
    return axios.patch(`/team/enable-member`, data);
  }

  disableTeamMember(data) {
    return axios.patch(`/team/disable-member`, data);
  }

  getMemberLogs(id) {
    return axios.get(`/team/get-member-logs/${id}`);
  }
}

export default new Routes();
