export const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

export const ADD_TEMPLATE_LOG_REQUEST = "ADD_TEMPLATE_LOG_REQUEST";
export const ADD_TEMPLATE_LOG_SUCCESS = "ADD_TEMPLATE_LOG_SUCCESS";
export const ADD_TEMPLATE_LOG_FAILURE = "ADD_TEMPLATE_LOG_FAILURE";

export const GET_TEMPLATE_LOG_REQUEST = "GET_TEMPLATE_LOG_REQUEST";
export const GET_TEMPLATE_LOG_SUCCESS = "GET_TEMPLATE_LOG_SUCCESS";
export const GET_TEMPLATE_LOG_FAILURE = "GET_TEMPLATE_LOG_FAILURE";

export const POST_TEMPLATE_REQUEST = "POST_TEMPLATE_REQUEST";
export const POST_TEMPLATE_SUCCESS = "POST_TEMPLATE_SUCCESS";
export const POST_TEMPLATE_FAILURE = "POST_TEMPLATE_FAILURE";

export const PATCH_TEMPLATE_REQUEST = "PATCH_TEMPLATE_REQUEST";
export const PATCH_TEMPLATE_SUCCESS = "PATCH_TEMPLATE_SUCCESS";
export const PATCH_TEMPLATE_FAILURE = "PATCH_TEMPLATE_FAILURE";

export const DE_ACTIVATE_TEMPLATE_REQUEST = "DE_ACTIVATE_TEMPLATE_REQUEST";
export const DE_ACTIVATE_TEMPLATE_SUCCESS = "DE_ACTIVATE_TEMPLATE_SUCCESS";
export const DE_ACTIVATE_TEMPLATE_FAILURE = "DE_ACTIVATE_TEMPLATE_FAILURE";

export const ACTIVATE_TEMPLATE_REQUEST = "ACTIVATE_TEMPLATE_REQUEST";
export const ACTIVATE_TEMPLATE_SUCCESS = "ACTIVATE_TEMPLATE_SUCCESS";
export const ACTIVATE_TEMPLATE_FAILURE = "ACTIVATE_TEMPLATE_FAILURE";

export const APPROVE_PUBLISH_REQUEST = "APPROVE_PUBLISH_REQUEST";
export const APPROVE_PUBLISH_SUCCESS = "APPROVE_PUBLISH_SUCCESS";
export const APPROVE_PUBLISH_FAILURE = "APPROVE_PUBLISH_FAILURE";

// export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
// export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
// export const EDIT_PROJECT_FAILURE = "EDIT_PROJECT_FAILURE";

// export const POST_PROGRAM_REQUEST = "POST_PROGRAM_REQUEST";
// export const POST_PROGRAM_SUCCESS = "POST_PROGRAM_SUCCESS";
// export const POST_PROGRAM_FAILURE = "POST_PROGRAM_FAILURE";

// export const EDIT_PROGRAM_REQUEST = "EDIT_PROGRAM_REQUEST";
// export const EDIT_PROGRAM_SUCCESS = "EDIT_PROGRAM_SUCCESS";
// export const EDIT_PROGRAM_FAILURE = "EDIT_PROGRAM_FAILURE";

// export const POST_PORTFOLIO_REQUEST = "POST_PORTFOLIO_REQUEST";
// export const POST_PORTFOLIO_SUCCESS = "POST_PORTFOLIO_SUCCESS";
// export const POST_PORTFOLIO_FAILURE = "POST_PORTFOLIO_FAILURE";

// export const EDIT_PORTFOLIO_REQUEST = "EDIT_PORTFOLIO_REQUEST";
// export const EDIT_PORTFOLIO_SUCCESS = "EDIT_PORTFOLIO_SUCCESS";
// export const EDIT_PORTFOLIO_FAILURE = "EDIT_PORTFOLIO_FAILURE";
