/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get templates saga
function* getTemplatesSaga(action) {
  try {
    const result = yield call(api.getTemplates, action.payload);
    yield put({
      type: types.GET_TEMPLATES_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATES_FAILURE,
      payload: error,
    });
  }
}

//add template saga
function* addTemplateSaga(action) {
  try {
    const result = yield call(api.addTemplate, action.payload);
    yield put({
      type: types.POST_TEMPLATE_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.POST_TEMPLATE_FAILURE,
      payload: error,
    });
  }
}

//edit template saga
function* editTemplateSaga(action) {
  try {
    const result = yield call(api.editTemplate, action.payload);
    yield put({
      type: types.PATCH_TEMPLATE_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.PATCH_TEMPLATE_FAILURE,
      payload: error,
    });
  }
}

//edit template saga
function* activateTemplateSaga(action) {
  try {
    const result = yield call(api.activateTemplate, action.payload);
    yield put({
      type: types.ACTIVATE_TEMPLATE_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.ACTIVATE_TEMPLATE_FAILURE,
      payload: error,
    });
  }
}

//edit template saga
function* deActivateTemplateSaga(action) {
  try {
    const result = yield call(api.deActivateTemplate, action.payload);
    yield put({
      type: types.DE_ACTIVATE_TEMPLATE_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.DE_ACTIVATE_TEMPLATE_FAILURE,
      payload: error,
    });
  }
}

//get template logs saga
function* getTeplateLogsSaga(action) {
  try {
    const result = yield call(api.getTemplateLogs, action.payload);
    yield put({
      type: types.GET_TEMPLATE_LOG_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TEMPLATE_LOG_FAILURE,
      payload: error,
    });
  }
}

//add template logs saga
function* addTemplateLogsSaga(action) {
  try {
    const result = yield call(api.addTemplateLogs, action.payload);
    yield put({
      type: types.ADD_TEMPLATE_LOG_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_TEMPLATE_LOG_FAILURE,
      payload: error,
    });
  }
}

//approve and publish saga
function* approveAndPublish(action) {
  try {
    const result = yield call(api.approveAndPublish, action.payload);
    yield put({
      type: types.APPROVE_PUBLISH_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.APPROVE_PUBLISH_FAILURE,
      payload: error,
    });
  }
}

export function* TemplateWorkStreamWatcher() {
  yield takeLatest(types.GET_TEMPLATES_REQUEST, getTemplatesSaga);
  yield takeLatest(types.POST_TEMPLATE_REQUEST, addTemplateSaga);
  yield takeLatest(types.PATCH_TEMPLATE_REQUEST, editTemplateSaga);
  yield takeLatest(types.ACTIVATE_TEMPLATE_REQUEST, activateTemplateSaga);
  yield takeLatest(types.DE_ACTIVATE_TEMPLATE_REQUEST, deActivateTemplateSaga);
  yield takeLatest(types.ADD_TEMPLATE_LOG_REQUEST, addTemplateLogsSaga);
  yield takeLatest(types.GET_TEMPLATE_LOG_REQUEST, getTeplateLogsSaga);
  yield takeLatest(types.APPROVE_PUBLISH_REQUEST, approveAndPublish);
}
