import axios from "../../api/axiosConfig";

//get workstreams
export const getWorkStreams = (data) => {
  return axios.post(`/workStream/get-workstreams/${data.adminId}`, data.data);
};

//add project
export const addWorkstream = (data) => {
  return axios.post(`/workStream/add-workstream/${data.id}`, data.data);
};

//edit project
export const editProject = (data) => {
  return axios.patch(`/workStream/edit-project/${data.id}`, data.data);
};

//add program
// export const addProgram = (data) => {
//   return axios.post(`/workStream/add-program/${data.id}`, data.data);
// };

//edit program
export const editProgram = (data) => {
  return axios.patch(`/workStream/edit-program/${data.id}`, data.data);
};

//add portfolio
// export const addPortFolio = (data) => {
//   return axios.post(`/workStream/add-portfolio/${data.id}`, data.data);
// };

//edit program
export const editPortfolio = (data) => {
  return axios.patch(`/workStream/edit-portfolio/${data.id}`, data.data);
};

//activate workstrem
export const activateWorkStream = (data) => {
  return axios.patch(`/workStream/activate/${data.id}`);
};

//de-activate workstream
export const deActivateStream = (data) => {
  return axios.patch(`/workStream/de-activate/${data.id}`);
};

//quickstart workstream
export const quickstartWorkstream = (data) => {
  return axios.patch(`/workStream/quickstart/${data.id}`, data.data);
};
