/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get departments
function* getDepartmentsSaga(action) {
  try {
    const result = yield call(api.getDepartments, action.payload);
    yield put({
      type: types.GET_DEPARTMENT_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

//add department
function* addDepartmentSaga(action) {
  try {
    const result = yield call(api.addDepartment, action.payload);
    yield put({
      type: types.ADD_DEPARTMENT_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

//edit department
function* editDepartmentSaga(action) {
  try {
    const result = yield call(api.editDepartment, action.payload);
    yield put({
      type: types.EDIT_DEPARTMENT_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

//delete department
function* deleteDepartmentSaga(action) {
  try {
    const result = yield call(api.deleteDepartment, action.payload);
    yield put({
      type: types.DELETE_DEPARTMENT_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_DEPARTMENT_FAILURE,
      payload: error,
    });
  }
}

//get teams
function* getTeamsSaga(action) {
  try {
    const result = yield call(api.getTeams, action.payload);
    yield put({
      type: types.GET_TEAM_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TEAM_FAILURE,
      payload: error,
    });
  }
}

//add team
function* addTeamSaga(action) {
  try {
    const result = yield call(api.addTeam, action.payload);
    yield put({
      type: types.ADD_TEAM_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_TEAM_FAILURE,
      payload: error,
    });
  }
}

//edit team
function* editTeamSaga(action) {
  try {
    const result = yield call(api.editTeam, action.payload);
    yield put({
      type: types.EDIT_TEAM_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_TEAM_FAILURE,
      payload: error,
    });
  }
}

//delete team
function* deleteTeamSaga(action) {
  try {
    const result = yield call(api.deleteTeam, action.payload);
    yield put({
      type: types.DELETE_TEAM_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_TEAM_FAILURE,
      payload: error,
    });
  }
}

//get team members
function* getTeamMembersSaga(action) {
  try {
    const result = yield call(api.getTeamMembers, action.payload);
    yield put({
      type: types.GET_TEAM_MEMBER_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TEAM_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//add team member
function* addTeamMemberSaga(action) {
  try {
    const result = yield call(api.addTeamMember, action.payload);
    yield put({
      type: types.ADD_TEAM_MEMBER_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_TEAM_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//edit team member
function* editTeamMemberSaga(action) {
  try {
    const result = yield call(api.editTeamMember, action.payload);
    yield put({
      type: types.EDIT_TEAM_MEMBER_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_TEAM_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//edit team member
function* deleteTeamMemberSaga(action) {
  try {
    const result = yield call(api.deleteTeamMember, action.payload);
    yield put({
      type: types.DELETE_TEAM_MEMBER_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_TEAM_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//get team member logs
function* getMemberLogsSaga(action) {
  try {
    const result = yield call(api.getMemberLogs, action.payload);
    yield put({
      type: types.GET_MEMBER_LOGS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TEAM_MEMBER_FAILURE,
      payload: error,
    });
  }
}

// send invitation
function* sendInvitationSaga(action) {
  try {
    const result = yield call(api.sendInvitation, action.payload);
    yield put({
      type: types.SEND_INVITATION_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.SEND_INVITATION_FAILURE,
      payload: error,
    });
  }
}

// enable member
function* enableTeamMemberSaga(action) {
  try {
    const result = yield call(api.enableTeamMember, action.payload);
    yield put({
      type: types.ENABLE_TEAM_MEMBER_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.ENABLE_TEAM_MEMBER_FAILURE,
      payload: error,
    });
  }
}

// disable member
function* disableTeamMemberSaga(action) {
  try {
    const result = yield call(api.disableTeamMember, action.payload);
    yield put({
      type: types.DISABLE_TEAM_MEMBER_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.DISABLE_TEAM_MEMBER_FAILURE,
      payload: error,
    });
  }
}

// delete many
function* deleteManySaga(action) {
  try {
    const result = yield call(api.deleteMany, action.payload);
    yield put({
      type: types.DELETE_MANY_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_TEAM_FAILURE,
      payload: error,
    });
  }
}

// modify status
function* modifyStatusSaga(action) {
  try {
    yield put({
      type: types.MODIFY_STATUS_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.MODIFY_STATUS_FAILURE,
      payload: error,
    });
  }
}

export function* teamWatcher() {
  yield takeLatest(types.GET_DEPARTMENT_REQUEST, getDepartmentsSaga);
  yield takeLatest(types.ADD_DEPARTMENT_REQUEST, addDepartmentSaga);
  yield takeLatest(types.EDIT_DEPARTMENT_REQUEST, editDepartmentSaga);
  yield takeLatest(types.DELETE_DEPARTMENT_REQUEST, deleteDepartmentSaga);
  yield takeLatest(types.GET_TEAM_REQUEST, getTeamsSaga);
  yield takeLatest(types.ADD_TEAM_REQUEST, addTeamSaga);
  yield takeLatest(types.EDIT_TEAM_REQUEST, editTeamSaga);
  yield takeLatest(types.DELETE_TEAM_REQUEST, deleteTeamSaga);
  yield takeLatest(types.GET_TEAM_MEMBER_REQUEST, getTeamMembersSaga);
  yield takeLatest(types.ADD_TEAM_MEMBER_REQUEST, addTeamMemberSaga);
  yield takeLatest(types.EDIT_TEAM_MEMBER_REQUEST, editTeamMemberSaga);
  yield takeLatest(types.DELETE_TEAM_MEMBER_REQUEST, deleteTeamMemberSaga);
  yield takeLatest(types.SEND_INVITATION_REQUEST, sendInvitationSaga);
  yield takeLatest(types.ENABLE_TEAM_MEMBER_REQUEST, enableTeamMemberSaga);
  yield takeLatest(types.DISABLE_TEAM_MEMBER_REQUEST, disableTeamMemberSaga);
  yield takeLatest(types.GET_MEMBER_LOGS_REQUEST, getMemberLogsSaga);
  yield takeLatest(types.DELETE_MANY_REQUEST, deleteManySaga);
  yield takeLatest(types.MODIFY_STATUS_REQUEST, modifyStatusSaga);
}
