import * as types from "./types";

const initState = {
  clients: [],
  loading: false,
  success: false,
  archiveClients: [],
  memberLogs: [],
};

const clientsReducer = (state = initState, action) => {
  switch (action.type) {
    //get clients
    case types.GET_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: action.payload.sort((a, b) => {
          const nameA = a.clientName.toLowerCase();
          const nameB = b.clientName.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        }),
        archiveClients: action.payload?.filter(
          (elem) => elem?.isEnabled == false
        ),
      };
    case types.GET_CLIENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add client
    case types.POST_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.POST_CLIENT_SUCCESS:
      let arr = [...state.clients, action.payload];
      return {
        ...state,
        loading: false,
        success: true,
        clients: arr.sort((a, b) => {
          const nameA = a.clientName.toLowerCase();
          const nameB = b.clientName.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        }),
      };
    case types.POST_CLIENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add client
    case types.EDIT_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: state.clients?.map((item) =>
          item?._id == action.payload._id ? action.payload : item
        ),
      };
    case types.EDIT_CLIENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //enable client
    case types.ENABLE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ENABLE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: state.clients?.map((item) =>
          item?._id == action.payload._id ? action.payload : item
        ),
        archiveClients: state?.filter(
          (elem) => elem?._id !== action.payload?._id
        ),
      };
    case types.ENABLE_CLIENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //disable client
    case types.DISABLE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DISABLE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: state.clients?.map((item) =>
          item?._id == action.payload._id ? action.payload : item
        ),
        archiveClients: [...state.archiveClients, action.payload],
      };
    case types.DISABLE_CLIENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add client team member
    case types.ADD_CLIENT_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_CLIENT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: state.clients?.map((item) =>
          item?._id == action.payload?._id ? action.payload : item
        ),
      };
    case types.ADD_CLIENT_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit client team member
    case types.EDIT_CLIENT_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_CLIENT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: action.payload,
      };
    case types.EDIT_CLIENT_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete client team member
    case types.DELETE_CLIENT_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_CLIENT_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: state.clients?.map((item) =>
          item?._id == action.payload?._id ? action.payload : item
        ),
      };
    case types.DELETE_CLIENT_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //send invitation
    case types.SEND_CLIENT_INVITATION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.SEND_CLIENT_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        memberLogs: action.payload.logs,
      };
    case types.SEND_CLIENT_INVITATION_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get client member logs
    case types.GET_CLIENT_MEMBER_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_CLIENT_MEMBER_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        memberLogs: action.payload,
      };
    case types.GET_CLIENT_MEMBER_LOGS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    // //enable team member
    // case types.ENABLE_CLIENT_MEMBER_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.ENABLE_CLIENT_MEMBER_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     clients: state?.clients?.map((item) =>
    //       item?._id == action.payload?._id ? action.payload : item
    //     ),
    //   };
    // case types.ENABLE_CLIENT_MEMBER_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    // //disable team member
    // case types.DISABLE_CLIENT_MEMBER_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };
    // case types.DISABLE_CLIENT_MEMBER_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     success: true,
    //     clients: state?.clients?.map((item) =>
    //       item?._id == action.payload?._id ? action.payload : item
    //     ),
    //   };
    // case types.DISABLE_CLIENT_MEMBER_FAILURE:
    //   return {
    //     ...state,
    //     loading: true,
    //     success: false,
    //   };

    default:
      return state;
  }
};

export default clientsReducer;
