export const GET_CONFIGURATION_REQUEST = "GET_CONFIGURATION_REQUEST";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAILURE = "GET_CONFIGURATION_FAILURE";

export const POST_CONFIGURATION_REQUEST = "POST_CONFIGURATION_REQUEST";
export const POST_CONFIGURATION_SUCCESS = "POST_CONFIGURATION_SUCCESS";
export const POST_CONFIGURATION_FAILURE = "POST_CONFIGURATION_FAILURE";

export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";

export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILURE = "ADD_ROLE_FAILURE";

export const EDIT_ROLE_REQUEST = "EDIT_ROLE_REQUEST";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAILURE = "EDIT_ROLE_FAILURE";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const ROLE_MEMBERS_REQUEST = "ROLE_MEMBERS_REQUEST";
export const ROLE_MEMBERS_SUCCESS = "ROLE_MEMBERS_SUCCESS";
export const ROLE_MEMBERS_FAILURE = "ROLE_MEMBERS_FAILURE";

export const FILTER_MEMBERS_REQUEST = "FILTER_MEMBERS_REQUEST";
export const FILTER_MEMBERS_SUCCESS = "FILTER_MEMBERS_SUCCESS";
export const FILTER_MEMBERS_FAILURE = "FILTER_MEMBERS_FAILURE";

export const CLEAR_CONFIG_REQUEST = "CLEAR_CONFIG_REQUEST";
export const CLEAR_CONFIG_SUCCESS = "CLEAR_CONFIG_SUCCESS";
export const CLEAR_CONFIG_FAILURE = "CLEAR_CONFIG_FAILURE";

export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE";

export const CREATE_USAGE_REQUEST = "CREATE_USAGE_REQUEST";
export const CREATE_USAGE_SUCCESS = "CREATE_USAGE_SUCCESS";
export const CREATE_USAGE_FAILURE = "CREATE_USAGE_FAILURE";

export const GET_MEMBERS_REQUEST = "GET_MEMBERS_REQUEST";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAILURE = "GET_MEMBERS_FAILURE";
