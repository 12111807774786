import axios from "../../api/axiosConfig";

//get workstreams
export const getActivities = (data) => {
  return axios.get(`/pmo/get-activities/${data}`);
};

//add project
export const addActivity = (data) => {
  return axios.post(`/pmo/add-activity/${data.id}`, data.data);
};

//convert type
export const convertType = (data) => {
  return axios.patch(`/pmo/edit-type/${data.id}`, data.data);
};

//delete activity
export const deleteActivity = (data) => {
  return axios.delete(`/pmo/delete-activity/${data}`);
};

//edit activity
export const editActivity = (data) => {
  return axios.patch(`/pmo/edit-activity/${data.id}`, data.data);
};

//add log
export const addLog = (data) => {
  return axios.post(`/pmo/add-log/${data.id}`, data.data);
};

//get logs
export const getLogs = (data) => {
  return axios.get(`/pmo/get-logs/${data}`);
};

//get archive activities
export const getArchivedActivities = (data) => {
  return axios.post(`/pmo/get-archived-activities`, data);
};

//get activities by templates
export const getActivitiesByTemplates = (data) => {
  return axios.patch(`/pmo/get-activities-by-templates`, data);
};

//swap pmo activities
export const swapPMOActivities = (data) => {
  return axios.patch(`/pmo/swap-pmo-activities`, data);
};

// //get activity client
// export const getActivityClient = (data) => {
//   return axios.get(`/activity/get-activity-client/${data?.data}/${data?.type}`);
// };

// //upload media
// export const uploadMedia = (data) => {
//   return axios.patch(`/activity/upload-media/${data.id}`, data.data);
// };

// //delete media
// export const deleteMedia = (data) => {
//   return axios.patch(`/activity/delete-media`, data);
// };

// //my activities
// export const myActivities = (data) => {
//   return axios.get(`/activity/my-activities/${data}`);
// };

// //get media
// export const getMedia = (data) => {
//   return axios.get(`/activity/get-media/${data}`);
// };

// //get media by id
// export const getMediaById = (data) => {
//   return axios.get(`/activity/get-media-byId/${data}`);
// };
