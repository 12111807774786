import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedMenu({ children }) {
  const { configuration } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.auth);

  if (
    configuration?.adminId?.reporting == false ||
    !user?.systemRole?.menu?.includes("reporting") ||
    user?.systemRole?.reportingPermissions.length == 0
  ) {
    return <Navigate to="/admin" replace />;
  }

  return children;
}

export default ProtectedMenu;
