import * as types from "./types";

const initState = {
  departments: [],
  teams: [],
  teamMembers: [],
  codeModal: false,
  userId: null,
  success: false,
  memberLogs: [],
  totalMembers: [],
  loading: false,
};

const teamsReducer = (state = initState, action) => {
  switch (action.type) {
    //get departments
    case types.GET_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        departments: action.payload,
      };
    case types.GET_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add department
    case types.ADD_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        departments: [...state.departments, action.payload],
      };
    case types.ADD_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit department
    case types.EDIT_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        departments: state.departments?.map((item) =>
          item?._id == action.payload.data._id ? action.payload.data : item
        ),
      };
    case types.EDIT_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete department
    case types.DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        departments: state.departments?.filter((item) =>
          item?._id == action.payload.data._id ? null : item
        ),
      };
    case types.DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get teams
    case types.GET_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teams: action.payload,
      };
    case types.GET_TEAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add team
    case types.ADD_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teams: [...state.teams, action.payload],
      };
    case types.ADD_TEAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit team
    case types.EDIT_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teams: state.teams?.map((item) =>
          item?._id == action.payload.data._id ? action.payload.data : item
        ),
      };
    case types.EDIT_TEAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete team
    case types.DELETE_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teams: state.teams?.filter((item) =>
          item?._id == action.payload.data._id ? null : item
        ),
      };
    case types.DELETE_TEAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get team members
    case types.GET_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: action.payload?.teamMembers,
        totalMembers: action.payload?.totalMembers,
      };
    case types.GET_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add TEAM_MEMBER
    case types.ADD_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_TEAM_MEMBER_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: action.payload
          ? [...state.teamMembers, action.payload]
          : state.teamMembers,
      };
    case types.ADD_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit TEAM_MEMBER
    case types.EDIT_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: state.teamMembers?.map((item) =>
          item?._id == action.payload.data._id ? action.payload.data : item
        ),
      };
    case types.EDIT_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete TEAM_MEMBER
    case types.DELETE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: state.teamMembers?.filter((item) =>
          item?._id == action.payload.data._id ? null : item
        ),
      };
    case types.DELETE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //send invitation
    case types.SEND_INVITATION_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        memberLogs: action.payload.logs,
      };
    case types.SEND_INVITATION_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get member logs
    case types.GET_MEMBER_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_MEMBER_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        memberLogs: action.payload,
      };
    case types.GET_MEMBER_LOGS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //enable team member
    case types.ENABLE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ENABLE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: state.teamMembers?.map((item) =>
          item?._id == action.payload._id ? action.payload : item
        ),
      };
    case types.ENABLE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //disable team member
    case types.DISABLE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DISABLE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: state.teamMembers?.map((item) =>
          item?._id == action.payload._id ? action.payload : item
        ),
      };
    case types.DISABLE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete many
    case types.DELETE_MANY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_MANY_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        departments: state.departments?.filter((item) =>
          action.payload.includes(item?._id) ? null : item
        ),
        teams: state.teams?.filter((item) =>
          action.payload.includes(item?._id) ? null : item
        ),
        teamMembers: state.teamMembers?.filter((item) =>
          action.payload.includes(item?._id) ? null : item
        ),
      };
    case types.DELETE_MANY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //modify status saga
    case types.MODIFY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.MODIFY_STATUS_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        totalMembers: state.totalMembers?.map((elem) =>
          elem?._id == action.payload.id
            ? { ...elem, status: action.payload.status }
            : elem
        ),
      };
    case types.MODIFY_STATUS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default teamsReducer;
