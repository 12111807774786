import axios from "../axiosConfig";

class Routes {
  getUser(_id) {
    return axios.get(`/user/getUser/${_id}`);
  }

  biosFeedback(_id, data) {
    return axios.post(`/user/feedback/${_id}`, data);
  }

  createPlan(_id, plan) {
    return axios.patch(`/user/create-plan/${_id}/${plan}`);
  }

  cancelSubs(id, data) {
    return axios.patch(`/user/cancelSubs/${id}`, data);
  }

  getUserByEmail(email) {
    return axios.get(`/user/getUserByEmail/${email}`);
  }

  updateUser(_id, data) {
    return axios.patch(`/user/updateUser/${_id}`, data);
  }

  addSubRow(_id, data) {
    return axios.post(`/activity/add-sub-row/${_id}`, data);
  }

  getSubData(_id) {
    return axios.get(`/activity/get-sub-data/${_id}`);
  }

  deleteSubData(_id, data) {
    return axios.delete(`/activity/delete-sub-data/${_id}/${data}`);
  }

  editSubRow(_id, type, data) {
    return axios.patch(`/activity/edit-sub-data/${_id}/${type}`, data);
  }

  uploadMediaSubRow(data) {
    return axios.patch(`/activity/upload-media-subRow`, data);
  }

  getWorkstreamByTemplate(data) {
    return axios.get(`/workstream/get-workstream-by-template/${data}`);
  }
}

export default new Routes();
