export const GET_WORKSTREAMS_REQUEST = "GET_WORKSTREAMS_REQUEST";
export const GET_WORKSTREAMS_SUCCESS = "GET_WORKSTREAMS_SUCCESS";
export const GET_WORKSTREAMS_FAILURE = "GET_WORKSTREAMS_FAILURE";

export const POST_WORKSTREAM_REQUEST = "POST_WORKSTREAM_REQUEST";
export const POST_WORKSTREAM_SUCCESS = "POST_WORKSTREAM_SUCCESS";
export const POST_WORKSTREAM_FAILURE = "POST_WORKSTREAM_FAILURE";

export const EDIT_PROJECT_REQUEST = "EDIT_PROJECT_REQUEST";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_FAILURE = "EDIT_PROJECT_FAILURE";

// export const POST_PROGRAM_REQUEST = "POST_PROGRAM_REQUEST";
// export const POST_PROGRAM_SUCCESS = "POST_PROGRAM_SUCCESS";
// export const POST_PROGRAM_FAILURE = "POST_PROGRAM_FAILURE";

export const EDIT_PROGRAM_REQUEST = "EDIT_PROGRAM_REQUEST";
export const EDIT_PROGRAM_SUCCESS = "EDIT_PROGRAM_SUCCESS";
export const EDIT_PROGRAM_FAILURE = "EDIT_PROGRAM_FAILURE";

// export const POST_PORTFOLIO_REQUEST = "POST_PORTFOLIO_REQUEST";
// export const POST_PORTFOLIO_SUCCESS = "POST_PORTFOLIO_SUCCESS";
// export const POST_PORTFOLIO_FAILURE = "POST_PORTFOLIO_FAILURE";

export const EDIT_PORTFOLIO_REQUEST = "EDIT_PORTFOLIO_REQUEST";
export const EDIT_PORTFOLIO_SUCCESS = "EDIT_PORTFOLIO_SUCCESS";
export const EDIT_PORTFOLIO_FAILURE = "EDIT_PORTFOLIO_FAILURE";

export const DE_ACTIVATE_REQUEST = "DE_ACTIVATE_REQUEST";
export const DE_ACTIVATE_SUCCESS = "DE_ACTIVATE_SUCCESS";
export const DE_ACTIVATE_FAILURE = "DE_ACTIVATE_FAILURE";

export const ACTIVATE_REQUEST = "ACTIVATE_REQUEST";
export const ACTIVATE_SUCCESS = "ACTIVATE_SUCCESS";
export const ACTIVATE_FAILURE = "ACTIVATE_FAILURE";

export const WORKSTREAM_QUICKSTART_REQUEST = "WORKSTREAM_QUICKSTART_REQUEST";
export const WORKSTREAM_QUICKSTART_SUCCESS = "WORKSTREAM_QUICKSTART_SUCCESS";
export const WORKSTREAM_QUICKSTART_FAILURE = "WORKSTREAM_QUICKSTART_FAILURE";
