/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
// import { errorNotification, successNotification } from "../../utils";
import * as api from "./service";
import * as types from "./types";
// import { token } from "../selectors";
// import { notification, message } from "antd";

function* activeUsers(action) {
  try {
    // const authtoken = yield select(token);
    // const result = yield call(api.liveSeenMessage, action.payload, authtoken);
    yield put({
      type: types.ACTIVE_USERS_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.ACTIVE_USERS_FAILURE,
      payload: error,
    });
  }
}

function* makeSocket(action) {
  try {
    // const authtoken = yield select(token);
    // const result = yield call(api.liveSeenMessage, action.payload, authtoken);
    yield put({
      type: types.SOCKET_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: types.SOCKET_FAILURE,
      payload: error,
    });
  }
}

function* getAllConversationsSaga(action) {
  try {
    const result = yield call(api.getAllConversations, action.payload);
    yield put({
      type: types.GET_ALL_CONVERSATIONS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ALL_CONVERSATIONS_FAILURE,
      payload: error,
    });
  }
}

function* editConversationsSaga(action) {
  try {
    yield put({
      type: types.EDIT_CONVERSATIONS_SUCCESS,
      payload: action?.payload,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_CONVERSATIONS_FAILURE,
      payload: error,
    });
  }
}

export function* socketWatcherSaga() {
  yield takeLatest(types.ACTIVE_USERS_REQUEST, activeUsers);
  yield takeLatest(types.SOCKET_REQUEST, makeSocket);
  yield takeLatest(
    types.GET_ALL_CONVERSATIONS_REQUEST,
    getAllConversationsSaga
  );
  yield takeLatest(types.EDIT_CONVERSATIONS_REQUEST, editConversationsSaga);
}
