/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get configuration
function* getConfigurationSaga(action) {
  try {
    const result = yield call(api.getConfiguration, action.payload);
    yield put({
      type: types.GET_CONFIGURATION_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_CONFIGURATION_FAILURE,
      payload: error,
    });
  }
}

//add configuration
function* addConfigurationSaga(action) {
  try {
    const result = yield call(api.addConfiguration, action.payload);
    yield put({
      type: types.POST_CONFIGURATION_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.POST_CONFIGURATION_FAILURE,
      payload: error,
    });
  }
}

//get roles
function* getRolesSaga(action) {
  try {
    const result = yield call(api.getRoles, action.payload);
    yield put({
      type: types.GET_ROLE_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ROLE_FAILURE,
      payload: error,
    });
  }
}

//add role
function* addRoleSaga(action) {
  try {
    const result = yield call(api.addRole, action.payload);
    yield put({
      type: types.ADD_ROLE_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_ROLE_FAILURE,
      payload: error,
    });
  }
}

//edit role
function* editRoleSaga(action) {
  try {
    const result = yield call(api.editRole, action.payload);
    yield put({
      type: types.EDIT_ROLE_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_ROLE_FAILURE,
      payload: error,
    });
  }
}

//delete role
function* deleteRoleSaga(action) {
  try {
    const result = yield call(api.deleteRole, action.payload);
    yield put({
      type: types.DELETE_ROLE_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_ROLE_FAILURE,
      payload: error,
    });
  }
}

//get members by role
function* getMembersRoleSaga(action) {
  try {
    const result = yield call(api.getMembersByRole, action.payload);
    yield put({
      type: types.ROLE_MEMBERS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.ROLE_MEMBERS_FAILURE,
      payload: error,
    });
  }
}

//get members by role
function* filterMembersSaga(action) {
  //;
  try {
    yield put({
      type: types.FILTER_MEMBERS_SUCCESS,
      payload: action?.payload,
    });
  } catch (error) {
    yield put({
      type: types.FILTER_MEMBERS_FAILURE,
      payload: error,
    });
  }
}

//get members by role
function* clearConfigSaga(action) {
  //;
  try {
    yield put({
      type: types.CLEAR_CONFIG_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.CLEAR_CONFIG_FAILURE,
      payload: error,
    });
  }
}

//get members by role
function* getInvoices(action) {
  //;
  const result = yield call(api.getInvoices, action.payload);
  try {
    yield put({
      type: types.GET_INVOICES_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_INVOICES_FAILURE,
      payload: error,
    });
  }
}

// create usage saga
function* createUsageSaga(action) {
  //;
  const result = yield call(api.createUaage, action.payload);
  try {
    yield put({
      type: types.CREATE_USAGE_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.CREATE_USAGE_FAILURE,
      payload: error,
    });
  }
}

//get members
function* getAllMembersSaga(action) {
  try {
    const result = yield call(api.getAllMembers, action.payload);
    yield put({
      type: types.GET_MEMBERS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_MEMBERS_FAILURE,
      payload: error,
    });
  }
}

export function* adminWatcher() {
  yield takeLatest(types.GET_CONFIGURATION_REQUEST, getConfigurationSaga);
  yield takeLatest(types.POST_CONFIGURATION_REQUEST, addConfigurationSaga);
  yield takeLatest(types.GET_ROLE_REQUEST, getRolesSaga);
  yield takeLatest(types.ADD_ROLE_REQUEST, addRoleSaga);
  yield takeLatest(types.EDIT_ROLE_REQUEST, editRoleSaga);
  yield takeLatest(types.DELETE_ROLE_REQUEST, deleteRoleSaga);
  yield takeLatest(types.ROLE_MEMBERS_REQUEST, getMembersRoleSaga);
  yield takeLatest(types.FILTER_MEMBERS_REQUEST, filterMembersSaga);
  yield takeLatest(types.CLEAR_CONFIG_REQUEST, clearConfigSaga);
  yield takeLatest(types.GET_INVOICES_REQUEST, getInvoices);
  yield takeLatest(types.CREATE_USAGE_REQUEST, createUsageSaga);
  yield takeLatest(types.GET_MEMBERS_REQUEST, getAllMembersSaga);
}
