import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedAuthRoute from "./protectedAuthRoutes";
import ProtectedCancelSubsRoute from "./protectedCancelSubs";
// import ProtectedAdminRoute from "./protectedAdminRoutes";
import { ProtectedRoute, ProtectedPages } from "./protectedRoutes";
import ProtectedReporting from "./protectedMenus/protectedMenuReporting";
import ProtectedClients from "./protectedMenus/protectedMenuClients";
import ProtectedTeams from "./protectedMenus/protectedMenuTeams";
import ProtectedPmo from "./protectedMenus/protectedMenuPmo";
import ProtectedActivity from "./protectedMenus/protectedMenuActivity";
import ProtectedAdmin from "./protectedMenus/protectedMenuAdmin";
import ProtectedChat from "./protectedMenus/protectedMenuChat";
import Loader from "../components/Loader/Loader";
// import CareGiverRoutes from "./careGiverProfileRoutes";

const StartFree = lazy(() => import("../screens/Auth/startFree"));
const Signup = lazy(() => import("../screens/Auth/Signup"));
const Auth = lazy(() => import("../screens/Auth/auth"));
const SignupBoarding = lazy(() => import("../screens/Auth/Boarding"));
const ForgetPassword = lazy(() => import("../screens/Auth/ForgetPassword"));
const Profile = lazy(() => import("../screens/Profile/Profile"));

const AdminProfile = lazy(() => import("../screens/Admin/AdminProfile"));
const Activities = lazy(() => import("../screens/Activities/index"));
const PMOActivities = lazy(() => import("../screens/PMO-Activities/index"));
const Team = lazy(() => import("../screens/Team/Team"));
const Client = lazy(() => import("../screens/Client/Client"));
const Reporting = lazy(() => import("../screens/Reporting/index"));
const Viewer = lazy(() => import("../screens/Activities/docViewer"));
const FileViewer = lazy(() => import("../utils/fileViewer"));
const LandingPage = lazy(() => import("../screens/landingPage/index"));
const Contactus = lazy(() => import("../screens/contactus"));
const HowWorks = lazy(() => import("../screens/how-works"));
const PricingPlans = lazy(() => import("../screens/pricingPlans"));
const PrivacyStatement = lazy(() =>
  import("../screens/PrivacyStatement/index")
);
const SecSystem = lazy(() => import("../screens/sec-system"));
const SecDisclosure = lazy(() => import("../screens/sec-disclosure"));
const SecPolicy = lazy(() => import("../screens/sec-policy"));
const CookiePolicy = lazy(() => import("../screens/cookiepolicy"));
const PrivacyPolicy = lazy(() => import("../screens/privacy-policy"));
const TermsOfUSe = lazy(() => import("../screens/terms-of-use"));

const CancelSubscription = lazy(() => import("../screens/Admin/cancelSubs"));
const UnAuthorized = lazy(() => import("../screens/unAuthorized"));
const Chat = lazy(() => import("../screens/chat"));

export default function AppRoutes() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ProtectedAuthRoute>
              <LandingPage />
            </ProtectedAuthRoute>
          }
        />
        <Route exact path="/privacy-statement" element={<PrivacyStatement />} />
        <Route exact path="/un-authorized" element={<UnAuthorized />} />
        <Route exact path="/terms-of-use" element={<TermsOfUSe />} />
        <Route exact path="/contact-us" element={<Contactus />} />
        <Route exact path="/how-it-works" element={<HowWorks />} />
        <Route exact path="/pricing-and-plans" element={<PricingPlans />} />
        <Route exact path="/security-system" element={<SecSystem />} />
        <Route exact path="/security-disclosure" element={<SecDisclosure />} />
        <Route exact path="/security-policy" element={<SecPolicy />} />
        <Route exact path="/cookie-policy" element={<CookiePolicy />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/chat"
          element={
            <ProtectedRoute>
              <ProtectedChat>
                <Chat />
              </ProtectedChat>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/cancel-subscription"
          element={
            <ProtectedCancelSubsRoute>
              <CancelSubscription />
            </ProtectedCancelSubsRoute>
          }
        />

        <Route
          exact
          path="/on-boarding-steps"
          element={
            <ProtectedAuthRoute>
              <SignupBoarding />
            </ProtectedAuthRoute>
          }
        />

        <Route
          exact
          path="/auth"
          element={
            <ProtectedAuthRoute>
              <Auth />
            </ProtectedAuthRoute>
          }
        />

        <Route
          exact
          path="/start-free"
          element={
            <ProtectedAuthRoute>
              <StartFree />
            </ProtectedAuthRoute>
          }
        />

        <Route
          exact
          path="/member-signup/:id"
          element={
            <ProtectedAuthRoute>
              <Signup />
            </ProtectedAuthRoute>
          }
        />

        <Route
          exact
          path="/forgetPassword"
          element={
            <ProtectedAuthRoute>
              <ForgetPassword />
            </ProtectedAuthRoute>
          }
        />

        <Route
          exact
          path="/admin/*"
          element={
            <ProtectedRoute>
              <AdminProfile />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/doc-viewer/:_id"
          element={
            <ProtectedRoute>
              <Viewer />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/file-viewer/:_id"
          element={
            <ProtectedRoute>
              <FileViewer />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/reporting/*"
          element={
            <ProtectedRoute>
              <ProtectedReporting>
                <Reporting />
              </ProtectedReporting>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/activities/*"
          element={
            <ProtectedRoute>
              <ProtectedActivity>
                <Activities />
              </ProtectedActivity>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/pmo-activities/*"
          element={
            <ProtectedRoute>
              <ProtectedPmo>
                <PMOActivities />
              </ProtectedPmo>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/teams"
          element={
            <ProtectedRoute>
              <ProtectedTeams>
                <Team />
              </ProtectedTeams>
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/clients"
          element={
            <ProtectedRoute>
              <ProtectedClients>
                <Client />
              </ProtectedClients>
            </ProtectedRoute>
          }
        />
      </Routes>
    </React.Suspense>
  );
}
