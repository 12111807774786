import * as types from "./types";

export const activeUsers = (data) => {
  return {
    type: types.ACTIVE_USERS_REQUEST,
    payload: data,
  };
};

export const makeSockets = (data) => {
  return {
    type: types.SOCKET_REQUEST,
    payload: data,
  };
};

export const getConversations = (data) => {
  return {
    type: types.GET_ALL_CONVERSATIONS_REQUEST,
    payload: data,
  };
};

export const editConversation = (data) => {
  return {
    type: types.EDIT_CONVERSATIONS_REQUEST,
    payload: data,
  };
};
