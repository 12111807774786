/* eslint-disable */
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as api from "./service";
import * as types from "./types";

//get clients
function* getClientsSaga(action) {
  try {
    const result = yield call(api.getClients, action.payload);
    yield put({
      type: types.GET_CLIENT_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_CLIENT_FAILURE,
      payload: error,
    });
  }
}

//post client
function* postClientsSaga(action) {
  try {
    const result = yield call(api.postClient, action.payload);
    yield put({
      type: types.POST_CLIENT_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.POST_CLIENT_FAILURE,
      payload: error,
    });
  }
}

//edit client
function* editClientsSaga(action) {
  try {
    const result = yield call(api.editClient, action.payload);
    yield put({
      type: types.EDIT_CLIENT_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_CLIENT_FAILURE,
      payload: error,
    });
  }
}

//enable client
function* enableClientsSaga(action) {
  try {
    const result = yield call(api.enableClient, action.payload);
    yield put({
      type: types.ENABLE_CLIENT_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.ENABLE_CLIENT_FAILURE,
      payload: error,
    });
  }
}

//disable client
function* disableClientsSaga(action) {
  try {
    const result = yield call(api.disableClient, action.payload);
    yield put({
      type: types.DISABLE_CLIENT_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.DISABLE_CLIENT_FAILURE,
      payload: error,
    });
  }
}

//add client team member
function* addClientTeamMemberSaga(action) {
  try {
    const result = yield call(api.addClientTeamMember, action.payload);
    yield put({
      type: types.ADD_CLIENT_MEMBER_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.ADD_CLIENT_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//edit client team member
function* editClientTeamMemberSaga(action) {
  try {
    const result = yield call(api.editClientTeamMember, action.payload);
    yield put({
      type: types.EDIT_CLIENT_MEMBER_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.EDIT_CLIENT_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//send invitation
function* sendClientInvitationSaga(action) {
  try {
    const result = yield call(api.sendClientInvitation, action.payload);
    yield put({
      type: types.SEND_CLIENT_INVITATION_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.SEND_CLIENT_INVITATION_FAILURE,
      payload: error,
    });
  }
}

// //disable client team member
// function* disableTeamMemberSaga(action) {
//   try {
//     const result = yield call(api.disableTeamMember, action.payload);
//     yield put({
//       type: types.DISABLE_CLIENT_MEMBER_SUCCESS,
//       payload: result?.data?.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.DISABLE_CLIENT_MEMBER_FAILURE,
//       payload: error,
//     });
//   }
// }

// //enable client team member
// function* enableTeamMemberSaga(action) {
//   try {
//     const result = yield call(api.enableTeamMember, action.payload);
//     yield put({
//       type: types.ENABLE_CLIENT_MEMBER_SUCCESS,
//       payload: result?.data?.data,
//     });
//   } catch (error) {
//     yield put({
//       type: types.ENABLE_CLIENT_MEMBER_FAILURE,
//       payload: error,
//     });
//   }
// }

//delete team member
function* deleteTeamMemberSaga(action) {
  try {
    const result = yield call(api.deleteTeamMember, action.payload);
    yield put({
      type: types.DELETE_CLIENT_MEMBER_SUCCESS,
      payload: result?.data?.data,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_CLIENT_MEMBER_FAILURE,
      payload: error,
    });
  }
}

//get client team member logs
function* getClientMemberLogsSaga(action) {
  try {
    const result = yield call(api.getClientMemberLogs, action.payload);
    yield put({
      type: types.GET_CLIENT_MEMBER_LOGS_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_CLIENT_MEMBER_LOGS_FAILURE,
      payload: error,
    });
  }
}

export function* clientWatcher() {
  yield takeLatest(types.GET_CLIENT_REQUEST, getClientsSaga);
  yield takeLatest(types.POST_CLIENT_REQUEST, postClientsSaga);
  yield takeLatest(types.EDIT_CLIENT_REQUEST, editClientsSaga);
  yield takeLatest(types.ADD_CLIENT_MEMBER_REQUEST, addClientTeamMemberSaga);
  yield takeLatest(types.EDIT_CLIENT_MEMBER_REQUEST, editClientTeamMemberSaga);
  yield takeLatest(
    types.SEND_CLIENT_INVITATION_REQUEST,
    sendClientInvitationSaga
  );
  // yield takeLatest(types.DISABLE_CLIENT_MEMBER_REQUEST, disableTeamMemberSaga);
  // yield takeLatest(types.ENABLE_CLIENT_MEMBER_REQUEST, enableTeamMemberSaga);
  yield takeLatest(types.DELETE_CLIENT_MEMBER_REQUEST, deleteTeamMemberSaga);
  yield takeLatest(types.DISABLE_CLIENT_REQUEST, disableClientsSaga);
  yield takeLatest(types.ENABLE_CLIENT_REQUEST, enableClientsSaga);
  yield takeLatest(
    types.GET_CLIENT_MEMBER_LOGS_REQUEST,
    getClientMemberLogsSaga
  );
}
