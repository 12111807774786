import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { TeamAPI } from "../../api";
import { useSelector } from "react-redux";

function ProtectedMenu({ children }) {
  const { configuration } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.auth);

  if (
    configuration?.adminId?.clients == false ||
    !user?.systemRole?.menu?.includes("clients")
  ) {
    return <Navigate to="/auth" replace />;
  }

  return children;
}

export default ProtectedMenu;
