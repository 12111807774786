import API from "../api/axiosConfig";

import { useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import MSIcon from "../assets/images/logo-ms.png";

export const AuthenticationButton = ({ setState }) => {
  const { instance, accounts } = useMsal();

  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["user.read"],
      });
      console.log("Login Response:", loginResponse);
      setState(loginResponse?.account);
    } catch (error) {
      console.error("Login Error:", error);
    }
  };

  return (
    <div>
      <button className="googleBtn" onClick={handleLogin}>
        <div>
          <img src={MSIcon} height={22} /> <span>Continue with Microsoft</span>
        </div>
      </button>
    </div>
  );
};

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://dev.projectbios.com", // Specify your redirect URI
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const headers = (token = null) => {
  if (token) {
    API.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
  return API.defaults.headers;
};

export const insertAndIncrement = (array, index, objectToInsert) => {
  if (index < 0 || index > array.length) {
    throw new Error("Index is out of bounds.");
  }

  // Increment the index field of the elements ahead of the insertion point.
  for (let i = index; i < array.length; i++) {
    array[i].index = array[i].index + 1;
  }

  // Insert the object at the specified index.
  array.splice(index, 0, objectToInsert);
  return array;
};

export const plans = [
  {
    key: 1,
    plan: "Admin Center",
    available: ["basic", "standard", "premium"],
  },
  {
    key: 2,
    plan: "My Activities Page",
    available: ["basic", "standard", "premium"],
  },
  {
    key: 3,
    plan: "Three Workstream Types",
    available: ["basic", "standard", "premium"],
    child: ["Project", "Program", "Portfolio"],
  },
  {
    key: 4,
    quantity: { basic: 20, standard: 60, premium: "unlimited" },
    plan: "Activities",
    available: ["basic", "standard", "premium"],
    child: [
      "Capture Progress",
      "Set Priorities",
      "Automated Administrative Updates based on Due Dates",
      "Manage Inputs/Outputs",
      "Document Risk and Mitigants",
      "Capture Process",
      "Unlimited Public Notes",
      "Unlimited Private Notes",
    ],
  },

  {
    key: 5,
    quantity: { basic: 20, standard: 60, premium: "unlimited" },
    plan: "Departments",
    available: ["basic", "standard", "premium"],
  },
  {
    key: 6,
    quantity: { basic: 20, standard: 60, premium: "unlimited" },
    plan: "Teams",
    available: ["basic", "standard", "premium"],
  },
  {
    key: 7,
    plan: "Manage Client Profiles",
    available: ["standard", "premium"],
  },
  {
    key: 8,
    plan: "Enable Client Access",
    available: ["standard", "premium"],
  },
  {
    key: 9,
    plan: "Assign Workstreams and Activities to Clients",
    available: ["standard", "premium"],
  },
  {
    key: 10,
    plan: "Manage Reports in Real Time",
    available: ["premium"],
  },
  {
    key: 11,
    plan: "See Portfolio Risks and Mitigants",
    available: ["premium"],
  },
  {
    key: 12,
    plan: "Manage Portfolio Productivity",
    available: ["premium"],
  },
  {
    key: 13,
    plan: "Track Leading Indicators for Delays",
    available: ["premium"],
  },
  {
    key: 14,
    plan: "Enable Dynamic Reporting on Activities Page",
    available: ["premium"],
  },
  {
    key: 15,
    plan: "PMO Enabled",
    available: ["standard", "premium"],
  },
  {
    key: 16,
    plan: "Artificial Intelligence Enabled",
    available: ["premium"],
  },
  {
    key: 17,
    quantity: { basic: 200, standard: 500, premium: "unlimited" },
    plan: "GB of Media (Videos and Documents) Storage Total",
    available: ["basic", "standard", "premium"],
  },
  {
    key: 18,
    plan: "Additional Storage is Charged at $25 per 100 GB per month",
    available: ["basic", "standard", "premium"],
  },
];

export const generalQuestions = [
  {
    question: "What is ProjectBIOS?",
    answer:
      "Work Management Software as a Service (SaaS) that can enhance team collaboration, improve task visibility, streamline workflows, boost productivity, and provide a centralized platform for communication and project documentation. It also helps meet project deadlines and milestones efficiently.",
  },
  {
    question: "Does ProjectBIOS support in-person and distributed teams?",
    answer:
      "Yes, Work Management SaaS is ideal for remote or distributed teams but also supports in person teams. It provides a centralized platform for teams to collaborate and manage projects from anywhere in the world, allowing team members to work together seamlessly regardless of their physical locations.",
  },
  {
    question:
      "How secure is project management SaaS for storing and managing sensitive project data?",
    answer:
      "ProjectBIOS provides robust security measures, including data encryption, access controls, regular data backups, and compliance with industry security standards. It's important to choose a reputable SaaS provider with a strong track record of data security to ensure the safety of your project data.",
  },
  {
    question: "Is there a free trial or a money back guarantee?",
    answer:
      "Yes, ProjectBIOS offers a robust 30-day free trial period so that you can experience the benefits firsthand and join hundreds of other companies already enjoying improved collaboration!",
  },
];

export const businessQuestions = [
  {
    question:
      "Can ProjectBIOS help my business with resource allocation and team management?",
    answer:
      "Yes, ProjectBIOS offers tools for resource allocation and team management. It allows you to assign tasks to team members, set priorities, and monitor workloads. This helps optimize resource allocation, prevent overloading individuals, and ensure that the right team members are working on the right tasks, ultimately improving project outcomes.",
  },
  {
    question:
      "What financial benefits can my business expect from using ProjectBIOS?",
    answer:
      "By streamlining project management processes and improving team productivity, Work Management SaaS can lead to cost savings. It helps businesses meet project deadlines more effectively, reduce the risk of costly delays, and improve project outcomes, resulting in better financial performance.",
  },
  {
    question:
      "How can my business measure the return on investment (ROI) of implementing ProjectBIOS?",
    answer:
      "Measuring the ROI of ProjectBIOS can be done by tracking various metrics, such as project completion times, resource utilization, reduced project costs, and increased client satisfaction. Comparing these metrics before and after implementing the software can help quantify the benefits and determine the ROI of the investment in ProjectBIOS for your business.",
  },
];

export const featureQuestions = [
  {
    question: "What does it mean to stack engagements in ProjectBIOS?",
    answer:
      "ProjectBIOS allows users to select multiple projects, programs, and portfolios into a single view to see how they are interconnected. Additionally, this allows users to invite clients to a section of an initiative while keeping other sections internal facing. Also, while reporting is available globally this allows custom reporting to show the selected engagements to meet various needs.",
  },
  {
    question: "What automation does ProjectBIOS provide?",
    answer:
      "ProjectBIOS updates many items in real time such as the risk register and productivity report. Additionally, using Project Management Institute best practices the status of items are evaluated based on progress and due date updating items to be at risk which are then made available in reporting and updates task assignments in individual ques when users are working in their “My Activities” view.",
  },
  {
    question: "What are public notes compared to private notes in ProjectBIOS?",
    answer:
      "We found many individuals keep their own lists with notes outside of project plans so we incorporated the ability for users to keep notes only they will see and enabling a collaborative notes section to where everyone can add updates. This is to reduce the need for users to maintain external lists and to reinforce ProjectBIOS as the source of truth reducing rework and status uncertainty.",
  },
  {
    question:
      "What is the difference between adding a team user and a client user?",
    answer:
      "Team users can see multiple client engagements, internal departments, teams, associates, portfolios, programs, and projects while the client view will only be allowed to see portfolios, programs, and projects that are assigned to the client they are associated.",
  },
];

export const checkPermission = (page, permission, systemRole) => {
  let res = true;
  switch (page) {
    case "admin":
      if (systemRole?.adminPagePermissions?.includes(permission)) {
        res = true;
      } else {
        res = false;
      }
      break;

    case "teams":
      if (systemRole?.teamPagePermissions?.includes(permission)) {
        res = true;
      } else {
        res = false;
      }
      break;

    case "clients":
      if (systemRole?.clientPagePermissions?.includes(permission)) {
        res = true;
      } else {
        res = false;
      }
      break;

    case "PMO":
      if (systemRole?.pmoPagePermissions?.includes(permission)) {
        res = true;
      } else {
        res = false;
      }
      break;

    case "activities":
      if (systemRole?.activitiesPermissions?.includes(permission)) {
        res = true;
      } else {
        res = false;
      }
      break;

    case "reporting":
      if (
        systemRole?.reportingPermissions?.includes(permission)
        // systemRole?.activityReportingPermissions?.includes(permission)
      ) {
        res = true;
      } else {
        res = false;
      }
      break;

    case "activity_reporting":
      if (systemRole?.activityReportingPermissions?.includes(permission)) {
        res = true;
      } else {
        res = false;
      }
      break;

    default:
      break;
  }

  return res;
};

export const getQueryParam = (name) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name);
};

export const normalizeUrl = (url) => {
  if (url) return url.startsWith("http") ? url : `http://${url}`;
};

export const MenuItems = [
  {
    title: "Admin",
    val: "admin",
    url: "/admin",
    cName: "nav-links",
  },
  {
    title: "Teams",
    val: "teams",
    url: "/teams",
    cName: "nav-links",
  },

  {
    title: "Activities",
    val: "activities",
    url: "/activities",
    cName: "nav-links",
  },
  {
    title: "PMO",
    val: "PMO",
    url: "/pmo-activities",
    cName: "nav-links",
  },
  {
    title: "Clients",
    val: "clients",
    url: "/clients",
    cName: "nav-links",
  },
  {
    title: "Reporting",
    val: "reporting",
    url: "/reporting",
    cName: "nav-links",
  },
];

export const removeDup = (arr) => {
  let result = [];

  result = Object.values(
    arr.reduce((acc, obj) => {
      acc[obj._id] = obj;
      return acc;
    }, {})
  );
  return result;
};

export const findTeamMember = (id, teamMembers, superAdmin) => {
  //;
  let obj = [...teamMembers, superAdmin]?.find((el) => el?._id == id);
  if (obj) {
    let full_name = obj?.firstName + " " + obj?.lastName;
    return full_name;
  } else {
    return "";
  }
};

export const find_Member = (id, teamMembers, superAdmin) => {
  let obj = [...teamMembers, superAdmin]?.find((el) => el?._id == id);
  return obj;
};

export const checkActiveMenu = (val) => {
  if (val == "activities" && window.location.href.includes("pmo-activities")) {
    return false;
  } else {
    if (window.location.href.includes(val)) return true;
    else return false;
  }
};

export const calculateBrightness = (color) => {
  //;
  const rgb = color?.substring(1); // Remove the #
  const r = parseInt(rgb?.substring(0, 2), 16); // Red value
  const g = parseInt(rgb?.substring(2, 4), 16); // Green value
  const b = parseInt(rgb?.substring(4, 6), 16); // Blue value
  return (r * 299 + g * 587 + b * 114) / 1000; // Calculate brightness
};

export const darkenColor = (hex, percent) => {
  // Remove the leading # if present
  hex = hex?.replace(/^#/, "");

  // Convert the hexadecimal color to RGB
  const r = parseInt(hex?.substring(0, 2), 16);
  const g = parseInt(hex?.substring(2, 4), 16);
  const b = parseInt(hex?.substring(4, 6), 16);

  // Darken the RGB values by the specified percentage
  const darkenValue = 1 - percent / 100;
  const darkenedR = Math?.round(r * darkenValue);
  const darkenedG = Math?.round(g * darkenValue);
  const darkenedB = Math?.round(b * darkenValue);

  // Convert the darkened RGB values back to hexadecimal
  const darkenedHex = ((darkenedR << 16) + (darkenedG << 8) + darkenedB)
    .toString(16)
    .padStart(6, "0");

  // Add the leading # back to the darkened hexadecimal color
  return `#${darkenedHex}`;
};

export const cleanUpHTML = (html) => {
  let res = convertUrlsToLinks(html);
  const parser = new DOMParser();
  const doc = parser.parseFromString(res, "text/html");
  const brElements = doc.querySelectorAll("p br");
  if (brElements.length > 0) {
    const lastBr = brElements[brElements.length - 1];
    if (lastBr.previousSibling && lastBr.previousSibling.nodeName === "BR") {
      lastBr.parentNode.removeChild(lastBr.previousSibling);
    }
    lastBr.parentNode.removeChild(lastBr);
  }
  return doc.body.innerHTML;
};

export const convertUrlsToLinks = (text) => {
  const urlPattern =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|www\.[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})\/?[-A-Z0-9+&@#\/%=~_|]*)/gi;
  return text.replace(urlPattern, (url) => {
    const href = url.startsWith("http") ? url : `http://${url}`;
    return `<a href="${href}" target="_blank" style="color: #79BAEC">${url}</a>`;
  });
};
