import axios from "../../api/axiosConfig";

//get templates
export const getTemplates = (adminId) => {
  return axios.get(`/template/get-all-templates/${adminId}`);
};

//add template
export const addTemplate = (data) => {
  return axios.post(`/template/add-template/${data.id}`, data.data);
};

//edit template
export const editTemplate = (data) => {
  return axios.patch(`/template/edit-template/${data.id}`, data.data);
};

//activate template
export const activateTemplate = (data) => {
  return axios.patch(`/template/activate/${data}`);
};

//de-activate template
export const deActivateTemplate = (data) => {
  return axios.patch(`/template/de-activate/${data}`);
};

//get template logs
export const getTemplateLogs = (data) => {
  return axios.get(`/template/get-logs/${data}`);
};

//add template logs
export const addTemplateLogs = (data) => {
  return axios.post(`/template/add-logs/${data.id}`, data.data);
};

//approve and publish
export const approveAndPublish = (data) => {
  return axios.patch(`/template/approve-and-publish/${data.id}`, data.data);
};
