export const POST_CLIENT_REQUEST = "POST_CLIENT_REQUEST";
export const POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS";
export const POST_CLIENT_FAILURE = "POST_CLIENT_FAILURE";

export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILURE = "EDIT_CLIENT_FAILURE";

export const GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";

export const ENABLE_CLIENT_REQUEST = "ENABLE_CLIENT_REQUEST";
export const ENABLE_CLIENT_SUCCESS = "ENABLE_CLIENT_SUCCESS";
export const ENABLE_CLIENT_FAILURE = "ENABLE_CLIENT_FAILURE";

export const DISABLE_CLIENT_REQUEST = "DISABLE_CLIENT_REQUEST";
export const DISABLE_CLIENT_SUCCESS = "DISABLE_CLIENT_SUCCESS";
export const DISABLE_CLIENT_FAILURE = "DISABLE_CLIENT_FAILURE";

export const ADD_CLIENT_MEMBER_REQUEST = "ADD_CLIENT_MEMBER_REQUEST";
export const ADD_CLIENT_MEMBER_SUCCESS = "ADD_CLIENT_MEMBER_SUCCESS";
export const ADD_CLIENT_MEMBER_FAILURE = "ADD_CLIENT_MEMBER_FAILURE";

export const EDIT_CLIENT_MEMBER_REQUEST = "EDIT_CLIENT_MEMBER_REQUEST";
export const EDIT_CLIENT_MEMBER_SUCCESS = "EDIT_CLIENT_MEMBER_SUCCESS";
export const EDIT_CLIENT_MEMBER_FAILURE = "EDIT_CLIENT_MEMBER_FAILURE";

export const SEND_CLIENT_INVITATION_REQUEST = "SEND_CLIENT_INVITATION_REQUEST";
export const SEND_CLIENT_INVITATION_SUCCESS = "SEND_CLIENT_INVITATION_SUCCESS";
export const SEND_CLIENT_INVITATION_FAILURE = "SEND_CLIENT_INVITATION_FAILURE";

// export const ENABLE_CLIENT_MEMBER_REQUEST = "ENABLE_CLIENT_MEMBER_REQUEST";
// export const ENABLE_CLIENT_MEMBER_SUCCESS = "ENABLE_CLIENT_MEMBER_SUCCESS";
// export const ENABLE_CLIENT_MEMBER_FAILURE = "ENABLE_CLIENT_MEMBER_FAILURE";

// export const DISABLE_CLIENT_MEMBER_REQUEST = "DISABLE_CLIENT_MEMBER_REQUEST";
// export const DISABLE_CLIENT_MEMBER_SUCCESS = "DISABLE_CLIENT_MEMBER_SUCCESS";
// export const DISABLE_CLIENT_MEMBER_FAILURE = "DISABLE_CLIENT_MEMBER_FAILURE";

export const DELETE_CLIENT_MEMBER_REQUEST = "DELETE_CLIENT_MEMBER_REQUEST";
export const DELETE_CLIENT_MEMBER_SUCCESS = "DELETE_CLIENT_MEMBER_SUCCESS";
export const DELETE_CLIENT_MEMBER_FAILURE = "DELETE_CLIENT_MEMBER_FAILURE";

export const GET_CLIENT_MEMBER_LOGS_REQUEST = "GET_CLIENT_MEMBER_LOGS_REQUEST";
export const GET_CLIENT_MEMBER_LOGS_SUCCESS = "GET_CLIENT_MEMBER_LOGS_SUCCESS";
export const GET_CLIENT_MEMBER_LOGS_FAILURE = "GET_CLIENT_MEMBER_LOGS_FAILURE";
