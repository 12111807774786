/* eslint-disable */
import axios from "../../api/axiosConfig";

//department apis
export const addDepartment = (data) => {
  return axios.post(`/team/add-department/${data.id}`, data.data);
};

export const getDepartments = (id) => {
  return axios.get(`/team/get-departments/${id}`);
};

export const editDepartment = (data) => {
  return axios.patch(`/team/edit-department/${data.id}`, data.data);
};

export const deleteDepartment = (id) => {
  return axios.delete(`/team/delete-department/${id}`);
};

//team apis
export const addTeam = (data) => {
  return axios.post(`/team/add-team/${data.id}`, data.data);
};

export const getTeams = (id) => {
  return axios.get(`/team/get-teams/${id}`);
};

export const editTeam = (data) => {
  return axios.patch(`/team/edit-team/${data.id}`, data.data);
};

export const deleteTeam = (id) => {
  return axios.delete(`/team/delete-team/${id}`);
};

//team members apis
export const addTeamMember = (data) => {
  return axios.post(`/team/add-team-member/${data.id}`, data.data);
};

export const getTeamMembers = (id) => {
  return axios.get(`/team/get-team-members/${id}`);
};

export const getMemberById = (id) => {
  return axios.get(`/team/get-member-byId/${id}`);
};

export const editTeamMember = (data) => {
  return axios.patch(`/team/edit-team-member/${data.id}`, data.data);
};

export const sendInvitation = (data) => {
  return axios.patch(`/team/send-invitation/${data.id}`, data.data);
};

export const deleteTeamMember = (id) => {
  return axios.delete(`/team/delete-team-member/${id}`);
};

export const enableTeamMember = (data) => {
  return axios.patch(`/team/enable-member`, data);
};

export const disableTeamMember = (data) => {
  return axios.patch(`/team/disable-member`, data);
};

export const getMemberLogs = (id) => {
  return axios.get(`/team/get-member-logs/${id}`);
};

export const deleteMany = (data) => {
  return axios.post(`/team/delete-many`, data);
};
