import axios from "../axiosConfig";

class Routes {
  getAllConversations(_id) {
    return axios.get(`/chat/get-all-conversations/${_id}`);
  }

  getConversation(senderId, receiverId, type) {
    return axios.get(
      `/chat/get-conversation/${senderId}/${receiverId}/${type}`
    );
  }

  getConversationById(id) {
    return axios.get(`/chat/get-conversation-byId/${id}`);
  }

  updateStatus(chatId, userId) {
    return axios.patch(`/chat/update-message-status/${chatId}/${userId}`);
  }

  addMessage(data) {
    return axios.post("/chat/add-message", data);
  }

  liveSeenMessage(data) {
    return axios.patch(
      `/chat/live-seen-message/${data?._id}/${data?.type}/${data?.userId}`
    );
  }

  seenMessage(data) {
    return axios.patch(`/chat/seen-message/${data?.userId}/${data?.chatId}`);
  }

  getContacts(id) {
    return axios.get(`/chat/get-contacts/${id}`);
  }

  getGroupConversation(id) {
    return axios.get(`/chat/get-group-conversation/${id}`);
  }

  newGroupConversation(data) {
    return axios.post(`/chat/new-group-conversation`, data);
  }

  leaveGroup(data) {
    return axios.patch(`/chat/leave-group/${data.id}/${data.userId}`);
  }

  deleteGroup(id) {
    return axios.delete(`/chat/delete-group/${id}`);
  }

  getFiles(id) {
    return axios.get(`/chat/get-files/${id}`);
  }

  getConversationFiles(id, cid, data) {
    return axios.patch(`/chat/conversation-files/${id}/${cid}`, data);
  }

  editMessage(id, data) {
    return axios.patch(`/chat/edit-message/${id}`, data);
  }

  editGroup(id, data) {
    return axios.patch(`/chat/edit-group/${id}`, data);
  }

  turnOffNotifications(id, data) {
    return axios.patch(`/chat/turn-off-notifications/${id}`, data);
  }

  turnOnNotifications(id, data) {
    return axios.patch(`/chat/turn-on-notifications/${id}`, data);
  }

  getWorkstreamChat(id, userId) {
    return axios.get(`/chat/workstream-chat/${id}/${userId}`);
  }

  getFile(id) {
    return axios.get(`/chat/get-file/${id}`);
  }
}

export default new Routes();
