import React from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedAuthRoute({ children }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [state, setState] = React.useState();

  React.useEffect(() => {
    if (user) setState(user);
  }, [user]);

  if (user && user.role == "admin" && user?.isFirstLogin == false) {
    return navigate("/admin");
  } else if (user && user?.isFirstLogin == true) {
    return navigate("/activities");
  }

  if (user && (user.role == "teamMember" || user.role == "clientMember")) {
    return <Navigate to="/profile" replace />;
  }
  return children;
}

export default ProtectedAuthRoute;
